<template>
  <div id="page4" class="h-100">
    <header-title :displayStepInfo="true">
      <template  v-slot:company>
        <company-info></company-info>
      </template>
      <template v-slot:stepInfo>Наші спільні цінності</template>
      <template  v-slot:step>3</template>
      <template  v-slot:text>{{ topValues.length }}/10 сенсів співпало, оберіть ще {{ 10 - topValues.length }} </template>
    </header-title>

    <div class="row d-flex flex-grow-1 step-main-row middle" v-bind:class="{ 'sc-disabled-area': !showReady }">
      <div id="selectedLeftValuesList" class="col-auto d-flex flex-column p-0 values-category">
        <div class="row text-center d-flex values-block-top" v-for="value in topValues" :key="value.id" :rating="value.rating">
          <div class="col d-flex flex-grow-1 justify-content-center align-items-center p-0">
            <span class="m-0">{{ value.title }}</span>
          </div>
        </div>
        <div class="row text-center d-flex values-block-empty"
             v-for="(priority, index) in leftValuesMock"
             @mouseover="showValuesList"
             @mouseout="hideValuesList"
             :ref="'leftItem' + index"
             :attr-index="index"
             :attr-offset-index="topValues.length + index + 1">
          <div class="col d-flex flex-grow-1 justify-content-center align-items-center p-0">
            <span class="m-0 item">{{ prefix(priority.title) }}</span>
          </div>
        </div>
      </div>
      <div class="сol-auto d-flex flex-column w-25 p-0">
        <div class="row m-0">
          <div id="addValuesList" class="col values-select-list invisible">
            <div class="row text-center d-flex values-block-option"
                 v-for="value in leftValues" :key="value.id" :rating="value.rating"
                 @mouseover="addHover"
                 @mouseout="removeHover"
                 @click="addValueToList(value)">
              <div class="col d-flex flex-grow-1 justify-content-center align-items-center p-0">
                <span class="m-0">{{ value.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-cols-3 step-main-row bottom">
      <players-state :current-phase="4">
        <template v-slot:hintContent>
          <p>Зараз ви бачите на екрані ті сенси, що об’єднують більшість.</p>

          <p>Тепер ваша спільна задача — погодити решту цінностей.</p>

          <p>Наводьте на «Цінність Х», потім на перелік праворуч, гортайте його, обирайте найважливіше.</p>

          <p><strong>PS</strong> Серед переліку можуть бути сенси важливі для інших.</p>

          <p>Як закінчите, тисніть «Готово», очікуйте, переходьте «Далі».</p>
        </template>
      </players-state>
      <div class="col d-flex align-items-center">
        <progress-bar @completed="onProgressBarCompleted" :total="leftValuesCount" ref="progressBarCmpS4"></progress-bar>
      </div>
      <div class="col d-flex align-items-center">
        <div class="row d-flex flex-grow-1">
          <div v-bind:class="{ invisible: !showWait  }" class="col-9 d-flex justify-content-center align-items-center">
            <span>{{ alertNextMessage }}</span>
          </div>
          <div class="col-3 d-flex justify-content-end p-0">
            <next-button @goto="onGoto" :ready-next="readyNext">
              <template v-slot:content v-if="showReady">
                <span>Готово</span>
              </template>
              <template v-slot:content v-else>
                <span>Далі</span>
                <i class="fas fa-arrow-right sc"></i>
              </template>
            </next-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'
import playersState from "./playersState";
import companyInfo from "./companyInfo";
import headerTitle from "./headerTitle";
import progressBar from "./progressBar";
import nextButton from "./nextButton";
import {mapState, mapWritableState, mapActions} from 'pinia';
import {useMainStore} from "@/stores/main";
// import s4AppMock from "@/components/data/S4AppMock";

export default {
  name: 'section4',
  components: {
    playersState,
    companyInfo,
    headerTitle,
    progressBar,
    nextButton
  },
  data: function () {
    return {
      readyNext: false,
      showWait: false,
      showReady: true,
      postPhase4: false,
      alertNextMessage: "Очікуємо на решту команди",
      once: false,
      isGameOver: false
    }
  },
  computed: {
    leftValuesCount() {
      return 10 - this.topValues.length;
    },
    ...mapActions(useMainStore, ['fetchPhase4']),
    ...mapState(useMainStore, ["tgpd", "firstClick"]),
    ...mapWritableState(useMainStore, ["isSection4", "isSection6", "isSection7", "resultedValues", "phase2Results",
      "topValues", "leftValues", "leftValuesMock", "arguableValues", "topValuesPreArguable"])
  },
  created() {
    if (!this.topValues.length || !this.tgpd.game) {
      this.fetchPhase4;
    }
  },
  watch: {
    firstClick: function (val) {
      if (val === true) {
        this.showReady = false;
        this.readyNext = false;
        this.postPhase4 = true;
        this.$refs.progressBarCmpS4.current = this.leftValues.length;
        setTimeout(this.getLeftValuesIntersection, 1000);
      }
    }
  },
  methods: {
    prefix: function (item) {
      return Number.isInteger(item) ? "Цінність " + item : item;
    },
    onProgressBarCompleted() {
      this.readyNext = true;
    },
    showValuesList: function (event) {
      if (this.fadeTimerID) {
        clearTimeout(this.fadeTimerID);
      }
      let valuesBlockEmpty = $(event.target).closest(".values-block-empty");
      this.currentIndex = valuesBlockEmpty.attr("attr-index");
      let currentOffsetIndex = valuesBlockEmpty.attr("attr-offset-index");
      $("#page4 div[attr-index]").each(function(index, item){
        item.classList.remove("hovered");
      });
      valuesBlockEmpty.addClass("hovered");
      let container = $("#addValuesList");
      let selectedList = $("#selectedLeftValuesList");
      container.removeClass("invisible");
      let currentOffset = currentOffsetIndex*(selectedList.height() - container.height())/10.0;
      // console.log(currentOffsetIndex, "currentOffset", currentOffset, "selectedList", selectedList.height(), "container", container.height());
      container.css('margin-top', currentOffset + "px");
    },
    hideValuesList: function (event) {
      this.fadeTimerID = setTimeout(function(){
        $("#page4 div[attr-index]").each(function(index, item){
          item.classList.remove("hovered");
        });
        $("#addValuesList").addClass("invisible");
      }, 1000);
    },
    addValueToList(currentItem) {
      if (!Number.isInteger(+this.leftValuesMock[this.currentIndex].title)) {
        this.leftValues.push(this.leftValuesMock[this.currentIndex]);
      } else {
        this.$refs.progressBarCmpS4.increase();
      }
      this.leftValuesMock[this.currentIndex] = currentItem;
      this.$refs["leftItem" + this.currentIndex][0].classList.add("picked");
      this.leftValues = this.leftValues.filter(item => item.title !== currentItem.title);

      $("#addValuesList").addClass("invisible");
    },
    addHover: function (event) {
      if (this.fadeTimerID) {
        clearTimeout(this.fadeTimerID);
      }
    },
    removeHover: function (event) {
      this.fadeTimerID = setTimeout(function(){
        $("#addValuesList").addClass("invisible");
      }, 100);
    },
    onGoto: function (event) {
      let self = this;

      if (self.readyNext && !self.showReady) {

        if (self.isGameOver) {
          this.isSection4 = false;
          this.isSection7 = true;
          this.$router.push({ path: '/phase7' })
        } else {
          this.isSection4 = false;
          this.isSection6 = true;
          this.$router.push({ path: '/phase6' })
        }
        return;
      }

      if (this.$refs.progressBarCmpS4.getCurrent() === self.leftValuesCount) {

        if (self.canGotoStep6) {
          self.gotoStep6();
          return;
        }

        self.showReady = false;
        self.readyNext = false;

        if (!self.postPhase4) {
          let selectedLeftValuesList = $("#selectedLeftValuesList").find(".item").map(function (index, item) {
            return self.leftValuesMock.find(function (el) {
              return el.title === item.innerText;
            });
          }).toArray();

          selectedLeftValuesList.sort(function (left, right) {
            return right.rating - left.rating;
          });

          let requestData = {
            "game": this.tgpd.game,
            "team": this.tgpd.team,
            "player": this.tgpd.player,
            "top": this.topValues,
            "left": selectedLeftValuesList
          };

          this.$axios.post("/phase4/", requestData).then(function(response) {
            self.postPhase4 = true;
            setTimeout(self.getLeftValuesIntersection, 1000);
          }).catch(function (error) {
            self.$log.error("Method: 'onGoto'. Url: '/phase4/'. Output: ", error, self.tgpd);
            self.postPhase4 = false;
            alert("Виникла мережева помилка! Повторіть будь ласка запит ще раз.")
          });
        } else {
          self.showWait = true;
          self.readyNext = false;
          self.alertNextMessage = "Очікуємо на решту команди";
          setTimeout(()=> self.showWait= false, 3000);
        }
      } else {
        self.showWait = true;
        self.readyNext = false;
        setTimeout(()=> self.showWait= false, 3000);
        self.alertNextMessage="Спочатку оберіть цінності";
      }
    },
    getLeftValuesIntersection: function(){
      let self = this;
      let requestData = {
        "game": this.tgpd.game,
        "team": this.tgpd.team
      };

      this.$axios.post("/phase5/", requestData).then(function(response) {
        if (Object.keys(response.data).length > 0) {
          self.$log.info("Method: 'getLeftValuesIntersection'. Url: '/phase5/'", "All players selected left values!", self.tgpd);
          if (response.data["top"].length !== 10) {
            self.arguableValues = response.data["arguable"];
            self.canGotoStep6 = true;
            self.topValuesPreArguable = response.data["top"];
            self.gotoStep6();
          } else {
            setTimeout(self.getFinalResult, 1000);
          }
        } else {
          if (!self.once) {
            self.showWait = true;
            self.readyNext = false;
            self.alertNextMessage = "Очікуємо на решту команди";
            setTimeout(() => self.showWait = false, 3000);
            self.once = true;
          }
          setTimeout(self.getLeftValuesIntersection, 1000);
        }
      }).catch(function (error) {
        self.$log.error("Method: 'getLeftValuesIntersection'. Url: '/phase5/'. Output: ", error, self.tgpd);
        setTimeout(self.getLeftValuesIntersection, 1000);
      });
    },
    gotoStep6: function (event) {
      if (this.canGotoStep6) {
        this.readyNext = true;
      }
    },
    getFinalResult: function() {
      let self = this;
      let requestData = {
        "game": this.tgpd.game,
        "team": this.tgpd.team
      };

      this.$axios.post("/phase7/", requestData).then(function(response) {
        if (response.data && response.data["resultedValues"] && response.data["resultedValues"].length > 0) {
          self.$log.info("Method: 'getFinalResult'. Url: '/phase7/'. Output: ", response.data, self.tgpd);
          self.resultedValues = response.data["resultedValues"];
          self.phase2Results = response.data["phase2Results"];
          self.isGameOver = true;
          self.readyNext = true;
        } else {
          setTimeout(self.getFinalResult, 1000);
        }
      }).catch(function (error) {
        self.$log.error("Method: 'getFinalResult'. Url: '/phase7/'. Output: ", error, self.tgpd);
        setTimeout(self.getFinalResult, 1000);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.values-category {
  padding: 0 8px;
  margin-left: 35%;
  width: 30%;
}

.values-block-top {
  border: 3px solid var(--bs-white);
  border-radius: 4px;
  height: 9%;
  color: black;
  width: 100%;
  margin: 0 0 -3px;
}

.values-block-option {
  border: 3px solid var(--bs-white);
  border-radius: 4px;
  color: black;
  margin: 0 0 1px;
  height: 40px;
}

.values-select-list {
  overflow-y: scroll;
  max-height: 261px;
  overflow-x: hidden;
  padding-right: 5px;
}

.values-block-empty {
  width: 100%;
  height: 9%;
  border-style: solid;
  border-color: var(--bs-white);
  border-radius: 4px;
  background: var(--bs-white);
  margin: 8px 0 0;
}

.values-block-empty.picked {
  border: 3px solid var(--bs-white) ;
}

.values-block-option:hover {
  border: 3px solid var(--bs-secondary);
  cursor: pointer;
}

.values-block-empty:hover {
  border: 3px solid var(--bs-secondary);
  background: white;
  cursor: pointer;
}


  .disabled-area {
    position: absolute;
    width: 100%;
    height: 62%;
    z-index: 10;
    top: 10%;
    bottom: 10%;
  }
</style>
