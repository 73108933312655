const initData = {
    isSection0: false,
    isSection1: false,
    isSection2: false,
    isSection4: false,
    isSection6: false,
    isSection7: false,
    firstClick: false,
    team: "",
    game: "",
    player: "",
    playersCount: 0,
    top10: [],
    groups: [],
    playerGroups: {},
    topValues: [],
    leftValuesMock: [],
    leftValues: [],
    leftValuesCount: 10,
    arguableValues: [],
    topValuesPreArguable: [],
    resultedValues: [],
    phase2Results: {},
    voted: false,
    unvoted: false,
    voteResult: 0,
    once: false,
    playerHovered: false,
    description: {}
}

export default initData