import { ref, computed } from 'vue'
import en from './en'
import ua from './ua'

// Available Languages
const availableLanguages = [
    {
        code: 'EN',
        name: 'English',
        file: en,
    },
    {
        code: 'UA',
        name: 'Українська',
        file: ua,
    },
    // Add more languages here
]

// Current Language Code
const currentLanguageCode = ref('UA')

// Current Language File
const t = computed(
    () => availableLanguages.find((lang) => lang.code === currentLanguageCode.value).file
)

// Set Language
const setLanguage = (code = 'UA') => {
    currentLanguageCode.value = code
    localStorage.setItem('language', code)
}

// Remember Language
if (localStorage.getItem('language')) {
    setLanguage(localStorage.getItem('language'))
}

export { availableLanguages, currentLanguageCode, t, setLanguage }