<template>
  <div class="d-inline-flex float-start justify-content-center align-items-center phase-hint">
    <div class="d-flex justify-content-center align-items-center hs-inner"
         v-bind:class="{ active: activeHint }" @click="show">
      <i class="hs-inner-icon" v-bind:class="{ active: activeHint }"></i>
    </div>
    <div class="hint-backdrop" v-show="activeHint" @click.prevent="activeHint=false"></div>
    <div class="card sc" v-show="activeHint" :style="{'bottom': bottom}">
      <div class="card-body p-0">
        <div class="container p-5">
          <div class="row g-0">
            <div class="col">
              <div class="col-auto d-flex justify-content-start align-items-center mb-40">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                  <path d="M24.0155 23.5586C24.1413 24.3571 23.6253 25.1428 22.8072 25.2696L18.8931 25.9793C18.0751 26.1314 17.3199 25.5864 17.1941 24.788L15.9481 17.8682C15.86 17.2852 15.13 17.0444 14.7021 17.4753L6.84872 25.4343C6.26979 26.0173 5.32587 26.0173 4.74693 25.4343L1.14745 21.8097C0.568517 21.2267 0.568517 20.3015 1.13487 19.7185L8.98827 11.7469C9.41618 11.316 9.18964 10.6062 8.59812 10.4922L1.72639 9.30085C0.933499 9.14877 0.392319 8.38836 0.518175 7.58992L1.1978 3.63576C1.34882 2.83732 2.10396 2.29236 2.89685 2.44444L12.6381 4.13003C13.1038 4.20607 13.4687 4.6243 13.4184 5.09322C13.0408 9.3769 17.0053 13.3437 21.2592 12.9508C21.7249 12.9128 22.1402 13.2677 22.2283 13.7366L24.0155 23.5586Z" fill="#003EDC"/>
                  <path d="M25.3547 7.88091C24.5492 9.40175 23.1774 10.2889 21.7678 10.5424C21.4658 10.5931 21.1763 10.6184 20.8742 10.6184C19.5905 10.6184 18.2942 10.1368 17.3125 9.14827C16.1169 7.91894 15.6512 6.24602 15.9155 4.6745C16.1043 3.68596 16.57 2.73544 17.3251 1.97502C18.3068 0.986479 19.6031 0.504883 20.8868 0.504883C22.6614 0.504883 24.4611 1.43005 25.4176 3.34377C26.1098 4.75054 26.0973 6.48682 25.3547 7.88091Z" fill="#003EDC"/>
                </svg>
                <span class="text-black mx-2 fs-3">{{ t.hint }}</span>
                <i @click.prevent="activeHint=false" :class="currentLanguageCode" class="fas fa-times fs-5 text-black cursor-pointer close"></i>
              </div>
              <slot name="content"></slot>
            </div>
          </div>
          <div class="row g-0">
            <div class="col">
              <a @click.prevent="activeHint=false"
                 class="btn btn-primary border border-secondary border-3" role="button">{{ t.Understood }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {i18nMixin} from "@/mixins/i18n";

export default {
  name: 'hints',
  mixins: [i18nMixin],
  props: ["bottom"],
  data() {
    return {
      activeHint: false,
    }
  },
  watch: {
  },
  computed: {
  },
  created() {
    this.activeHint = (!this.$cookies.isKey("firstGame"));
  },
  methods: {
    show() {
      this.activeHint = true;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .phase-hint {
    height: 40px;
    width: 40px;
    margin-left: 5px;
    min-height: 40px;
  }

  .hs-inner {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: var(--bs-secondary);
    margin: 0;
    padding: 0;
  }

  .hs-inner-icon {
    height: 24px;
    width: 24px;
    background:url('~@/assets/hint_inactive.png') no-repeat;
    background-size: 24px 24px;
    display: inline-block;
  }

  .hs-inner-icon.active {
    height: 24px;
    width: 24px;
    background:url('~@/assets/hint_active.png') no-repeat;
    background-size: 24px 24px;
    display: inline-block;
  }

  .hs-inner.active {
    background: var(--bs-primary);
  }

  .hs-inner:hover {
    background: var(--bs-primary);
    cursor: pointer;
  }

  .hint-backdrop {
    opacity: 0.9!important;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  .card.sc {
    font-family: 'Unbounded';
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.5px;
    width: 480px;
    position: absolute;
    left: 2%;
    z-index: 2;
    border: none;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 8px 8px 20px 0px rgba(0, 0, 0, 0.25);
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .close {
    position: relative;
    left: 45%;
    margin-top: -25px;
  }

  .close.EN {
    left: 75%!important;
    margin-top: -70px!important;
  }

  .border-secondary:hover {
    border: var(--bs-border-style) var(--bs-primary)!important;
  }

</style>
