<template>
  <div id="page1" class="h-100">
      <header-title :displayStepInfo="true">
        <template  v-slot:company>
          <company-info></company-info>
        </template>
        <template v-slot:stepInfo>{{ t.OurCommonSense }}</template>
        <template  v-slot:step>1</template>
        <template  v-slot:text>{{ t.Choose10SensesTitle }}</template>
      </header-title>

    <div class="row row-cols-5 d-flex flex-grow-1 step-main-row middle">
      <div class="col d-flex flex-column flex-grow-1 align-items-stretch values-category" v-for="group in groups">
        <div class="row text-center d-flex align-items-center border border-3 border-white values-block"
             v-for="item in group.values" @click="pick">
          <div class="col">
            <span :attr-id="item.id">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row row-cols-3 step-main-row bottom">
      <players-state :current-phase="1">
        <template v-slot:hintContent>
          <p v-html="t.Choose10SensesHint"></p>
        </template>
      </players-state>
      <div class="col d-flex align-items-center">
        <progress-bar @completed="onProgressBarCompleted" :total="10" ref="progressBarCmpS1"></progress-bar>
      </div>
      <div class="col d-flex align-items-center">
        <div class="row d-flex flex-grow-1">
          <div v-bind:class="{ invisible: !showWait  }" class="col-9 d-flex justify-content-center align-items-center">
            <span> {{ t.ChooseSensesWarning }}</span>
          </div>
          <div class="col-3 d-flex justify-content-end p-0">
            <next-button @goto="onGoto" :ready-next="readyNext"></next-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'
import playersState from "./playersState";
import companyInfo from "./companyInfo";
import headerTitle from "./headerTitle";
import progressBar from "./progressBar";
import nextButton from "./nextButton";
import {mapState, mapWritableState, mapActions} from 'pinia';
import {useMainStore} from "@/stores/main";
import {i18nMixin} from "@/mixins/i18n";

export default {
  name: 'section1',
  components: {
    playersState,
    companyInfo,
    headerTitle,
    progressBar,
    nextButton
  },
  mixins: [i18nMixin],
  data: function () {
    return {
      readyNext: false,
      showWait: false
    }
  },
  computed: {
    ...mapActions(useMainStore, ['fetchPhase1']),
    ...mapState(useMainStore, ["groups", "tgpd"]),
    ...mapWritableState(useMainStore, ["isSection1", "isSection2", "top10"])
  },
  created() {
    if (!this.groups.length || !this.tgpd.game) {
      this.fetchPhase1;
    }
  },
  methods: {
    onProgressBarCompleted() {
      this.readyNext = true;
    },
    pick: function (event) {
      if (event) {
        let button = $(event.target).closest(".values-block");
        if (!button.hasClass("sc-value-selected")) {
          if (this.$refs.progressBarCmpS1.getCurrent() !== 10) {
            button.addClass("sc-value-selected");
            this.$refs.progressBarCmpS1.increase();
          }
        } else {
          button.removeClass("sc-value-selected");
          this.$refs.progressBarCmpS1.decrease();
          this.readyNext = false;
        }
      }
    },
    onGoto() {
      if (this.$refs.progressBarCmpS1.getCurrent() === 10) {
        let top10 = $(".sc-value-selected").map(function (index, item) {
          let sp = $(item).find("span");
          return {title: sp.text(), id: +sp.attr("attr-id")};
        });

        let self = this;
        let requestData = {
          "game": this.tgpd.game,
          "team": this.tgpd.team,
          "player": this.tgpd.player,
          "results": top10.toArray()
        };
        this.$axios.post("/phase1/", requestData).then(function(response) {
          self.top10 = top10;
          self.isSection1 = false;
          self.isSection2 = true;
          self.$router.push({ path: '/phase2' })
        }).catch(function (error) {
          self.$log.error("Method: 'onGoto'. Url: '/phase1/'. Output: ", error, self.tgpd);
        });
      } else {
        this.showWait = true;
        let self = this;
        setTimeout(()=>self.showWait = false, 3000);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.values-category {
  padding: 0 8px;
}

.value-header {
  height: 6%;
  max-height: 30px;
  min-height: 24px;
  margin: 0 0 3%;
  border-radius: 4px;
  color: #C4C4C4;
  background: #F3F3F3;
}

.values-block {
  border-radius: 4px;
  min-height: 30px;
  max-height: 56px;
  margin: 0 0 3%;
  height: 7%;
  color: #C4C4C4;
  font-size: 14px;
}

.values-category:hover .values-block span {
  color: black;
}

.values-block:hover {
  border: solid 3px;
  border-color: rgba(var(--bs-secondary-rgb),var(--bs-border-opacity))!important;
  cursor: pointer;
}

.sc-value-selected {
  border: 3px solid;
  border-color: rgba(var(--bs-secondary-rgb),var(--bs-border-opacity))!important;
  color: black;
}

</style>
