<template>
  <div id="page7" class="h-100">
    <header-title :displayStepInfo="true">
      <template  v-slot:company>
        <company-info></company-info>
      </template>
      <template v-slot:stepInfo>Наші спільні цінності</template>
      <template  v-slot:step></template>
      <template  v-slot:text>Подобається наш спільний результат?</template>
    </header-title>
    <results-to-download :open="downloadModal" :team="tgpd.team" :players-count="playersCount" :top="resultedValues"
                         :vote-result="voteResult.toFixed(1) * 100"
                         :start-date="new Date().toLocaleDateString('uk-UA')"></results-to-download>
    <div class="row row-cols-1 align-items-center step-main-row middle">
      <div class="col">
        <div class="row row-cols-1">
          <div class="col d-flex flex-column align-items-center">
            <div class="row text-center d-flex values-block-top position-relative"
                 v-for="value in resultedValues.slice(0, 3)"
                 :class="valuePlayers[value['id']].map(p=>p.formatted).join(' ')">
              <div @mouseover.capture="playerHovered=true" @mouseout.capture="playerHovered=false"
               class="col-9 row-cols-1 d-flex flex-wrap position-absolute top-0 start-100 value-players invisible">

                <span @mouseover.capture="addPlayerHover" @mouseout.capture="removePlayerHover"
                      class="row d-flex justify-content-center align-items-center flex-grow-1 sc-player"
                      v-for="player in valuePlayers[value['id']]"
                      :data-cls="player.formatted">{{ player.original }}</span>
              </div>
              <div class="col-11 d-flex flex-grow-1 p-0">
                <span @mouseover.capture="addValueHover" @mouseout.capture="removeValueHover" :rating="value.rating"
                      class="p-0 d-flex w-100 h-100 justify-content-center align-items-center nw">{{ value.title }}</span>
              </div>
            </div>
          </div>
          <div class="col d-flex flex-column align-items-center sc-mt-15">
            <div class="row text-center d-flex values-block-top position-relative"
                 v-for="value in resultedValues.slice(3)"
                 :class="valuePlayers[value['id']].map(p=>p.formatted).join(' ')">
              <div @mouseover.capture="playerHovered=true" @mouseout.capture="playerHovered=false"
                   class="col-9 row-cols-1 d-flex flex-wrap position-absolute top-0 start-100 value-players invisible">

                <span @mouseover.capture="addPlayerHover" @mouseout.capture="removePlayerHover"
                      class="row d-flex justify-content-center align-items-center flex-grow-1 sc-player"
                      v-for="player in valuePlayers[value['id']]"
                      :data-cls="player.formatted">{{ player.original }}</span>
              </div>
              <div class="col-11 d-flex flex-grow-1 p-0">
                <span @mouseover.capture="addValueHover" @mouseout.capture="removeValueHover" :rating="value.rating"
                      class="p-0 d-flex w-100 h-100 justify-content-center align-items-center nw">{{ value.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <whats-next-modal :open="openModal"></whats-next-modal>
    <donate-modal :open="openDonate"></donate-modal>
    <div class="row row-cols-3 step-main-row bottom">
      <players-state :current-phase="7" ref="playerStateCmpS7">
        <template v-slot:hintContent v-if="!once">
          <p>Вітаємо усіх учасників!</p>

          <p>10 сенсів, що ви зараз бачите на екрані — це результат спільного командного вибору.</p>

          <p>Тепер ви можете побачити, який внесок зробив кожен учасник. Наводьте на цінність, праворуч з’явиться ім’я,
            наводьте на нього — дивіться.</p>

          <p>Якщо вам до вподоби обрані цінності, тисніть «Подобається», якщо ні, — «Не подобається».</p>
        </template>
        <template v-slot:hintContent v-else-if="once && !isAuthenticated">
          <p>Цей результат — гарна основа для подальшого обговорення та пропрацювання спільної культури команди або
            компанії.</p>

          <p>Можете завантажити поточний результат («Завантажити») та дізнатися більше про онлайн–сервіс SensCreed
            («Більше»).</p>

          <p>Також можете поділитися власним досвідом тестування, та залишити свій коментар щодо власних вражень від
            продукту («Що далі?»).</p>
        </template>
        <template v-slot:hintContent v-else-if="once && isAuthenticated">
          <p>Дякуємо тобі за участь у тестуванні нашого сервісу!</p>
          <p>Можеш завантажити результат
            («Завантажити») та дослідити усі результати твоєї команди («Мій стартап»).</p>
          <p>Нам цікаво почути твій фідбек щодо користі, зручності нашого сервісу («Що далі?»).</p>
          <p><strong>PS</strong> Також тут («Що далі?») ти знайдеш кілька порад щодо подальшого застосування цінностей на практиці.</p>
        </template>
      </players-state>
      <div v-bind:class="{ invisible: !showWait }"
           class="col-3 d-flex justify-content-center align-items-center position-absolute alert-next-message">
        <span>{{ alertNextMessage }}</span>
      </div>
      <div :class="{ 'd-flex': !once, 'd-none': once }"
           class="col justify-content-center align-items-center">
        <button @click.self.prevent="vote" class="btn btn-primary sc-vote-btn" type="button">Подобається</button>
        <button @click.self.prevent="unvote" class="btn sc-unvote-btn" type="button">Не дуже</button>
      </div>
      <div :class="{ 'd-flex': once, 'd-none': !once }"
           class="col justify-content-center align-items-center">
        <button @click.self.prevent="" class="btn btn-white bg-white border-white text-black sc-vote-result" type="button">
          <b class="nw">{{ voteResult.toFixed(1) * 100 }}%</b> узгодили результат
        </button>
      </div>
      <div class="col d-flex">
        <div class="row d-flex flex-grow-1 align-content-center justify-content-end">
          <div class="col-auto d-flex p-0 mx-3">
            <a @mouseover="addHover" @mouseout="removeHover"
                :class="{ 'text-black': once, 'text-dark': !once }"
                class="text-decoration-none" @click.pr.prevent="printResults" href="#">Завантажити</a>
            <i class="icon-download sc sc-ml-10" :class="{ 'active': once, }"></i>
          </div>
          <div :class="{ 'd-flex': isAuthenticated, 'd-none': !isAuthenticated }"
               class="col-auto p-0 justify-content-end">
            <a @mouseover="addHover" @mouseout="removeHover"
                :class="{ 'text-black': once, 'text-dark': !once }"
                class="text-decoration-none" target="_blank"
               :href="(once) ? '#/profile': '#/phase7'">Тестування</a>
            <i class="icon-profile sc sc-ml-10" :class="{ 'active': once, }"></i>
          </div>
          <div :class="{ 'd-flex': !isAuthenticated, 'd-none': isAuthenticated }"
               class="col-auto p-0 justify-content-end">
            <a @mouseover="addHover" @mouseout="removeHover"
                :class="{ 'text-black': once, 'text-dark': !once }"
                class="text-decoration-none" target="_blank"
                :href="(once) ? 'https://www.linkedin.com/company/senscreed/': '#/phase7'">Більше</a>
            <i class="icon-main sc sc-ml-10" :class="{ 'active': once, }"></i>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import playersState from "./playersState";
import companyInfo from "./companyInfo";
import headerTitle from "./headerTitle";
import subscribeModal from "./subscribeModal";
import nextButton from "./nextButton";
import {mapState, mapWritableState, mapActions} from 'pinia';
import {useMainStore} from "@/stores/main";
import WhatsNextModal from "@/components/whatsNextModal";
import DonateModal from "@/components/donateModal";
import ResultsToDownload from "@/components/resultsToDownload";
import $ from "jquery";


export default {
  name: 'section7',
  components: {
    DonateModal,
    WhatsNextModal,
    ResultsToDownload,
    playersState,
    subscribeModal,
    companyInfo,
    headerTitle,
    nextButton,
  },
  data: function () {
    return {
      displayPlayersCount: false,
      openModal: false,
      openDonate: false,
      downloadModal: false,
      showWait: false,
      readyNext: true,
      isAuthenticated: false,
      valuePlayers: {},
      playerValues: {},
      alertNextMessage: "Натисніть подобається чи ні",
    }
  },
  computed: {
    ...mapActions(useMainStore, ['fetchPhase7']),
    ...mapState(useMainStore, ["tgpd", "gameHash", "resultedValues", "phase2Results", "playersCount"]),
    ...mapWritableState(useMainStore, ["voted", "unvoted", "voteResult", "once", "playerHovered"])
  },
  created() {
    if (!this.resultedValues.length || !this.tgpd.game) {
      this.fetchPhase7;
    } else {
      this.buildIntersections();
    }
  },
  watch: {
    resultedValues: function (newVal, oldVal) {
      if (newVal !== "") {
        this.buildIntersections();
        this.getResults();
        this.$cookies.set("firstGame", true,"30d");
      }
    }
  },
  methods: {
    buildIntersections() {
      this.valuePlayers = {};
      for (let index in this.resultedValues) {
        let id = this.resultedValues[index]["id"];
        this.valuePlayers[id] = [];
        for (const [index, [player, top]] of Object.entries(Object.entries(this.phase2Results))) {
          if (top.some(obj => +obj["id"] === +id)){
            this.valuePlayers[id].push({"original": this.cutLastName(player),
              "formatted": "p" + (+index + 1)});
          }
        }
      }
    },
    cutLastName(fullName) {
      let [fn, ln] = fullName.split(" ");
      return (fullName === this.tgpd.player) ? `Я / ${fn} ${ln[0]}.` : `${fn} ${ln[0]}.`;
    },
    sendVote(decision) {
      let self = this;
      let requestData = {
        team: this.tgpd.team,
        game: this.tgpd.game,
        player: this.tgpd.player,
        decision: decision
      };
      let formData = new FormData();
      Object.entries(requestData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      this.$axios.post('/vote/', formData, {headers:{"Content-Type": "multipart/form-data"}}).then(function (response) {
        if (!self.once) {
          self.once = true;
        }
        self.getResults()
      }).catch(function (error) {
        alert(error);
      });
    },
    vote() {
      this.voted = true;
      this.sendVote(1);
    },
    unvote() {
      this.unvoted = true;
      this.sendVote(0);
    },
    getResults() {
      let self = this;
      this.$axios.get("/vote/?g=" + this.gameHash).then(function(response) {
        self.voteResult = response.data[0];
        if (response.data[1] !== 0) {
          setTimeout(()=>{self.getResults()}, 1000);
        } else {
          self.readyNext = true;
        }
      });
      this.$axios.get('/isauthenticated/').then(function(response) {
        self.isAuthenticated = true;
      }).catch(function (error) {
        self.isAuthenticated = false;
      });
    },
    onGoto() {
      if (this.readyNext) {
        this.openModal = !this.openModal;
      } else {
        self.showWait = true;
        self.readyNext = false;
        setTimeout(()=> self.showWait= false, 3000);
      }
    },
    addHover() {
      if (!this.readyNext) {
        this.showWait = true;
      }
    },
    addValueHover(event) {
      let parent = $(event.target).parents(".values-block-top");
      parent.addClass("border-secondary").css("z-index", 1);
      parent.find(".value-players").removeClass("invisible");
    },
    addPlayerHover(event) {
      let player = $(event.target);
      player.siblings().addClass("text-dark");
      let playerCls = "." + player.data("cls");
      $(playerCls).addClass("border-secondary").css("z-index", 1);
      this.playerHovered = true;
    },
    removeHover() {
      this.showWait = false;
    },
    removeValueHover(event) {
      let self = this;
      let parent = $(event.target).parents(".values-block-top");
      parent.removeClass("border-secondary").css("z-index", 0);
      setTimeout(()=>{
        if (!self.playerHovered) {
          parent.find(".value-players").addClass("invisible");
        }
      }, 25);
    },
    removePlayerHover(event) {
      let self = this;
      let player = $(event.target);
      let parent = player.parent();
      player.siblings().removeClass("text-dark");
      let playerCls = "." + player.data("cls");
      $(playerCls).removeClass("border-secondary").css("z-index", 0);
      this.playerHovered = false;
      setTimeout(()=>{
        if (!self.playerHovered && !parent.hasClass("invisible")) {
          parent.addClass("invisible");
        }
      }, 10);
    },
    printResults: async function (event) {
      let self = this;
      if (this.readyNext && this.once) {
        // this.openDonate = !this.openDonate;
        // setTimeout(()=>{
          self.downloadModal = !this.downloadModal;
        // }, 1000);
      } else {
        this.showWait = true;
        this.readyNext = false;
        setTimeout(()=> self.showWait= false, 3000);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .middle {
    height: 80%;
  }

  .values-block-top {
    border: 3px solid var(--bs-white);
    border-radius: 4px;
    height: 56px;
    color: black;
    width: 300px;
    margin: 0 0 -3px;
  }

  .value-players {
    color: black;
    margin: -3px 0 0 3px;
    background-color: white;
  }

  .sc-player {
    border: 3px solid var(--bs-white);
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: -3px;
  }

  .sc-player:hover {
    cursor: pointer;
  }

  .card.sc {
    width: 400px;
    position: relative;
    max-height: 600px;
  }

  .card-body.sc {
    width: 100%;
    border-radius: 3px;
    padding: 0 25px;
  }

  .card-title.sc {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    margin-top: 20px;
    margin-left: 25px;
    margin-bottom: 10px;
  }

  .sc-title-container {
    margin: 10px 0;
    padding: 0 25px;
  }

  hr.sc {
    width: 100%;
    border-color: #C4C4C4;
  }

  .sc-w-80p {
    width: 80%
  }

  .sc-w-20p {
    width: 20%
  }

  .sc-mb-10 {
    margin: 0 0 10px;
  }

  .sc-pt-30 {
    padding-top: 30px;
  }

  .sc-mt-15 {
    margin-top: 15px;
  }

  .sc-mt25-mb15 {
    margin: 25px 0 15px;
  }

  .sc-ml-10 {
    margin-left: 10px;
  }

  .icon-download {
    height: 25px;
    width: 25px;
    background:url('~@/assets/download.png') no-repeat;
    background-size: 25px 25px;
    display: inline-block;
  }

  .icon-download.active{
    height: 25px;
    width: 25px;
    background:url('~@/assets/download_active.png') no-repeat;
    background-size: 25px 25px;
    display: inline-block;
  }

  .icon-profile {
    height: 28px;
    width: 28px;
    background:url('~@/assets/profile.png') no-repeat;
    background-size: 28px 28px;
    display: inline-block;
  }

  .icon-profile.active {
    height: 28px;
    width: 28px;
    background:url('~@/assets/profile_active.png') no-repeat;
    background-size: 28px 28px;
    display: inline-block;
  }

  .icon-main {
    height: 28px;
    width: 28px;
    background:url('~@/assets/house.png') no-repeat;
    background-size: 28px 28px;
    display: inline-block;
  }

  .icon-main.active {
    height: 28px;
    width: 28px;
    background:url('~@/assets/house_active.png') no-repeat;
    background-size: 28px 28px;
    display: inline-block;
  }

  .sc-vote-btn {
    width: 140px;
    font-size: 13px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .sc-unvote-btn {
    background-color: #FFFFFF;
    color: black;
    width: 140px;
    font-size: 13px;
    border: solid 2px var(--bs-light);
    margin-right: 10px;
    margin-left: 10px;
  }

  .sc-unvote-btn:hover {
    border: solid 2px var(--bs-secondary);
  }

  .sc-vote-result {
    width: 300px;
    height: 48px;
  }

  .alert-next-message {
    left: 38%;
    bottom: 10%;
  }
</style>
