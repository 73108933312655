<template>
  <div class="col-6 d-flex justify-content-center align-items-center ff-unbounded">
    <div class="row m-2">
      <img v-show="currentLanguageCode == 'UA'" id="main-img" class="img-fluid" src="~@/assets/sc_general_UA.png" />
      <img v-show="currentLanguageCode == 'EN'" id="main-img" class="img-fluid" src="~@/assets/sc_general_EN.png" />
    </div>
  </div>
</template>

<script>
import {i18nMixin} from "@/mixins/i18n";

export default {
  name: "left-info-panel",
  mixins: [i18nMixin],
}
</script>

<style scoped>

  .ff-unbounded {
    font-family: Unbounded,serif!important;
  }

  @media (min-width: 900px) and (max-width: 1399px) {
    #main-img{
      width: 90%;
      height: 90%;
    }
  }

  @media (min-width: 1240px) and (max-width: 1599px) {
    #main-img{
      width: 90%;
      height: 70%;
    }
  }

  @media (min-width: 1600px) and (max-width: 1920px) {
    #main-img{
      width: 90%;
      height: 90%;
    }
  }

  @media (min-width: 1900px) {
    #main-img{
      width: 90%;
      height: 90%;
    }
  }

</style>