<script>

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default {
  name: 'App',
}
</script>

<style>
@import url('https://fonts.googleapis.com/');
@import url('https://fonts.gstatic.com/');
@import url('https://fonts.googleapis.com/css?family=Ubuntu');
@import url('https://fonts.googleapis.com/css?family=Lora');
@import url('https://fonts.googleapis.com/css?family=Unbounded');
@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@300;400;500;700&display=swap');

html {
  /* For Firefox */
  scrollbar-color: #99B2F1 #FFFFFF;
  scrollbar-width: auto;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 6px #99B2F1;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

#app {
  margin: 0;
  padding: 0;
  font-family: Unbounded,serif!important;
  height: 100%;
}

.step-main-row {
  margin-right: 0;
  margin-left: 0;
  padding-right: 2%;
  padding-left: 2%;
}

.top {
  height: 7%;
  border-bottom: 2px solid #99B2F1;
}

.middle {
  padding-right: 10%;
  padding-left: 10%;
  padding-top: 2%;
  height: 73%;
}

.bottom {
  height: 14%;
  padding-top: 1%;
}

.sc-disabled-area {
  pointer-events: none;
  opacity: 0.7;
}

:root {
  --bs-primary: #003EDC;
  --bs-primary-rgb: 0,62,220;
  --bs-secondary: #4D78E7;
  --bs-secondary-rgb: 77, 120, 231;
  --bs-light: #99B2F1;
  --bs-light-rgb: 153, 178, 241;
  --bs-white: #E6ECFC;
  --bs-white-rgb: 230, 236, 252;
  --bs-danger: #FF4D4D;
  --bs-danger-rgb: 255, 77, 77;
  --bs-black: #000000;
  --bs-black-rgb: 0, 0, 0;
  --bs-dark: #999999;
  --bs-dark-rgb: 153, 153, 153;

  --bs-link-color: #4D78E7;
  --bs-link-color-rgb: 77, 120, 231;
  --bs-link-hover-color: #003EDC;
}

.btn-primary {
  --bs-btn-bg: #4D78E7;
  --bs-btn-border-color: #4D78E7;
  --bs-btn-hover-bg: #003EDC;
  --bs-btn-hover-border-color: #003EDC;
  --bs-btn-active-bg: #4D78E7;
  --bs-btn-active-border-color: #4D78E7;
  --bs-btn-disabled-bg: #99B2F1;
  --bs-btn-disabled-border-color: #99B2F1;
}

input {
  border: solid 2px #4D78E7;
}

input:focus-visible {
  border: solid 2px #003EDC;
  box-shadow: none;
  outline: 0 none;
}

</style>
