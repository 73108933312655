<template>
  <div class="row step-main-row top">
    <div class="col d-flex justify-content-start align-self-center p-0">
      <p class="sc-company-title m-0"><slot name="company"></slot></p>
    </div>
    <div class="col-7 d-flex align-items-center justify-content-center sc-step-info h-100 border border-light border-2 border-top-0 border-bottom-0">
      <span class="sc-mr-15"><slot name="step"></slot></span>
      <span><slot name="text"></slot></span>
    </div>
    <div class="col text-end align-self-center p-0">
      <div class="row m-0">
        <div class="col d-flex justify-content-end align-self-center p-0 sc-pr-10">
          <p class="sc-company-title m-0"><slot name="stepInfo"></slot></p>
          <languages :in-the-game=true></languages>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Languages from "@/components/languages.vue";

export default {
  name: "header-title",
  components: {Languages},
  props: ['displayStepInfo']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.sc-company-title {
  font-size: 16px;
  font-weight: 500;
  margin: 0
}

.sc-step-info span {
  font-size: 16px;
  font-weight: 300;
}

.sc-mr-15 {
  margin-right: 15px;
}

.sc-pr-10 {
  padding-right: 10px;
}

.sc-fs-16 {
  font-size: 16px;
}

.sc-w-40 {
  width: 40px;
}

.step-info {
  width: 40px;
  height: 40px;
  border: 3px solid #C4C4C4;
  border-radius: 57px;
}

.step-info:hover {
  background-color: #C4C4C4;
  color: white;
  cursor: pointer;
}

.step-info span {
  font-size: 18px;
  font-family: Unbounded,serif!important;
  font-style: italic;
  font-weight: 500;
}
</style>
