<template>
  <div id="page2" class="h-100">
    <header-title :displayStepInfo="true">
      <template  v-slot:company>
        <company-info></company-info>
      </template>
      <template v-slot:stepInfo>Наші спільні цінності</template>
      <template  v-slot:step>2</template>
      <template  v-slot:text>Розташуйте найважливіші для нас сенси зверху</template>
    </header-title>

    <div class="row row-cols-5 d-flex flex-grow-1 step-main-row middle" v-bind:class="{ 'sc-disabled-area': !showReady }">
      <div class="col-auto d-flex flex-column flex-grow-1 align-items-center values-category">
        <draggable v-model="topList">
          <transition-group>
            <div class="row row-cols-2 m-0 sc-mb-8 move-item"
             v-for="(value, index) in topList" :key="value.id">
              <div class="col-1 d-flex align-items-center p-0">
                <span class="text-center text-secondary sc-w-20 user-select-none">{{ index + 1 }}</span>
              </div>
              <div class="col-11 p-0">
                <div class="row text-center d-flex border border-3 border-white values-block-sorted">
                  <div class="col-11 d-flex flex-grow-1 justify-content-center align-items-center p-0">
                    <span class="user-select-none">{{ value.title }}</span>
                  </div>
                  <div class="col-1 d-flex p-0">
                    <div class="row row-cols-1 m-0">
                      <div class="col m-0 p-0">
                        <i class="fas fa-angle-up text-light"></i>
                      </div>
                      <div class="col p-0 sc-mt-8m">
                        <i class="fas fa-angle-down text-light"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>

    <div class="row row-cols-3 step-main-row bottom">
      <players-state :current-phase="2">
        <template v-slot:hintContent>
          <p>Ви обрали 10 сенсів, важливих для взаємодії в команді або компанії. Тепер розташуйте ці слова у порядку
            зростання.</p>

          <p>Перетягуйте догори ті сенси, які найкраще описують вас.</p>

          <p><strong>PS</strong> Подумайте, що відрізняє саме ваш досвід від інших.</p>

          <p>Після розстановки пріоритетів, тисніть «Готово» та очікуйте на решту учасників. Коли усі будуть готові,
            тисніть «Далі».</p>
        </template>
      </players-state>
      <div class="col d-flex align-items-center"></div>
      <div class="col d-flex align-items-center">
        <div class="row d-flex flex-grow-1">
          <div v-bind:class="{ invisible: !showWait  }" class="col-9 d-flex justify-content-center align-items-center">
            <span>Очікуємо на решту команди</span>
          </div>
          <div class="col-3 d-flex justify-content-end p-0">
            <next-button @goto="onGoto" :ready-next="readyNext">
              <template v-slot:content v-if="showReady">
                <span>Готово</span>
              </template>
              <template v-slot:content v-else>
                <span>Далі</span>
                <i class="fas fa-arrow-right sc"></i>
              </template>
            </next-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'
import playersState from "./playersState";
import companyInfo from "./companyInfo";
import headerTitle from "./headerTitle";
import nextButton from "./nextButton";
import draggable from 'vuedraggable';
import {mapState, mapWritableState, mapActions} from 'pinia';
import {useMainStore} from "@/stores/main";

export default {
  name: 'section2',
  components: {
    playersState,
    companyInfo,
    headerTitle,
    nextButton,
    draggable
  },
  data: function () {
    return {
      readyNext: true,
      showWait: false,
      showReady: true,
      isGameOver: false
    }
  },
  computed: {
    topList: {
      get() {
        return this.top10
      },
      set(value) {
        this.top10 = value;
      }
    },
    ...mapActions(useMainStore, ['fetchPhase2']),
    ...mapState(useMainStore, ["tgpd", "firstClick"]),
    ...mapWritableState(useMainStore, ["isSection2", "isSection21", "isSection4", "isSection7", "top10",
      "resultedValues", "phase2Results", "topValues", "leftValues", "leftValuesMock", "playerGroups"])
  },
  created() {
    if (!this.top10.length || !this.tgpd.game) {
      this.fetchPhase2;
    }
  },
  watch: {
    firstClick: function (val) {
      if (val === true) {
        this.showReady = false;
        this.readyNext = false;
        this.postPhase2 = true;
        setTimeout(this.getAllPlayersResults, 1000);
      }
    }
  },
  methods: {
    onGoto() {

      if (this.readyNext && !this.showReady) {

        if (this.isGameOver) {
          this.isSection2 = false;
          this.isSection7 = true;
          this.$router.push({ path: '/phase7' })
        } else {
          if (this.topValues.length !== 0) {
            this.leftValuesMock = [];
            for (let i = this.topValues.length + 1; i <= 10; i++) {
              this.leftValuesMock.push({title: i});
            }
            this.isSection2 = false;
            this.isSection4 = true;
            this.$router.push({ path: '/phase4' })
          } else {
            this.isSection2 = false;
            this.isSection21 = true;
            this.$router.push({ path: '/phase21' })
          }
        }
        return;
      }

      let requestData = {
        "game": this.tgpd.game,
        "team": this.tgpd.team,
        "player": this.tgpd.player,
        "results": (Array.isArray(this.top10)) ? this.top10 : this.top10.toArray(),
      };

      let self = this;
      if (!self.postPhase2) {
        this.$axios.post("/phase2/", requestData).then(function(response) {
          self.showReady = false;
          self.readyNext = false;
          setTimeout(self.getAllPlayersResults, 1000);
        }).catch(function (error) {
          self.$log.error("Method: 'onGoto'. Url: '/phase2/'. Output: ", error, self.tgpd);
          self.postPhase2 = false;
          alert("Виникла мережева помилка! Повторіть будь ласка запит ще раз.")
        });
        self.postPhase2 = true;
      }
    },
    getAllPlayersResults() {
      let self = this;
      let requestData = {
        "game": this.tgpd.game,
        "team": this.tgpd.team,
        "phase": "Phase2",
        "player": this.tgpd.player
      };

      this.$axios.post("/phase3/", requestData).then(function(response) {
        self.activeUser = {
          name: self.tgpd.player,
          values: Array.from(response.data[self.tgpd.player])
        };
        let usersValues = response.data;
        let isFinished = true;
        for (let player in usersValues) {
          if (player !== self.tgpd.player && usersValues[player].length === 0) {
            isFinished = false;
            self.showWait = true;
            self.readyNext = false;
            break;
          }
        }
        if (isFinished) {
          self.$log.info("Method: 'getAllPlayersResults'. Url: '/phase3/'", "All players sorted values!", self.tgpd);
          self.showWait = false;
          self.readyNext = false;
          self.processUserValues(usersValues);
        } else {
          setTimeout(self.getAllPlayersResults, 1000);
        }
      }).catch(function (error) {
        self.$log.error("Method: 'getAllPlayersResults'. Url: '/phase3/'. Output: ", error, self.tgpd);
        setTimeout(self.getAllPlayersResults, 1000);
      });
    },
    processUserValues(usersValues) {
      let self = this;
      let allValues = [];
      let userValuesSorted = {};
      for (let key in usersValues) {
        let currentUserValues = [];
        userValuesSorted[key] = [];
        for (let index=0; index<usersValues[key].length; index++) {
          let item = usersValues[key][index];
          userValuesSorted[key].push(item);
          currentUserValues.push({id:item.id, rating: 10 - index, title: item.title});
        }
        allValues = allValues.concat(currentUserValues);
      }

      let valuesCount = {};
      allValues.forEach(function(item) {
        if (item.id in valuesCount) {
          valuesCount[item.id].count += 1;
          valuesCount[item.id].rating += item.rating;
        } else {
          valuesCount[item.id] = {count: 1, rating: item.rating, title: item.title};
        }
      });

      let valuesRating = {};
      let topValues = [];
      let topValuesDict = {};
      let leftValues = [];

      let threshold = Object.keys(usersValues).length * 0.51;
      Object.entries(valuesCount).forEach(function(el){
        let curRating = Math.pow(10, el[1].count - 1) + el[1].rating;
        valuesRating[el[0]] = curRating;
        if (el[1].count >= threshold) {
          topValues.push({id: el[0], title: el[1].title, rating: curRating});
          topValuesDict[el[0]] = el[1].title;
        } else {
          leftValues.push({id: el[0], title: el[1].title, rating: curRating});
        }
      });

      for (let key in userValuesSorted) {
        for (let i=0; i<userValuesSorted[key].length; i++) {
          userValuesSorted[key][i].order = valuesRating[userValuesSorted[key][i].id];
          userValuesSorted[key][i].inTop = userValuesSorted[key][i].id in topValuesDict;
        }
      }

      let fnCmp = (l, r) => {
        return (r.rating - l.rating) || (l.title.localeCompare(r.title));
      }

      for (let key in userValuesSorted) {
        userValuesSorted[key].sort(fnCmp);
      }

      topValues.sort(fnCmp);

      leftValues.sort(fnCmp)

      if (topValues.length !== 0) {
        self.$log.info(`Phase 2 post phase3save. User ${this.tgpd.player}. topValues length is-> ${topValues.length}`);
        let requestData = {
          "game": this.tgpd.game,
          "team": this.tgpd.team,
          "top": topValues,
          "left": leftValues
        };

        this.$axios.post("/phase3save/", requestData).then(function(response) {
          if ((10 - topValues.length) === 0) {
            self.$log.info(`Phase 2 start getFinalResult. User ${self.tgpd.player} topValues length is-> ${topValues.length}`);
            setTimeout(self.getFinalResult, 1000);
          } else {
            self.topValues = topValues;
            self.leftValues = leftValues;
            self.isGameOver = false;
            self.readyNext = true;
            self.$log.info(`Phase 2 isGameOver false. User ${self.tgpd.player}. topValues length is-> ${topValues.length}`);
          }
        }).catch(function (error) {
          self.$log.error("Method: 'processUserValues'. Url: '/phase3save/'. Output: ", error, self.tgpd);
          setTimeout(self.getAllPlayersResults, 1000);
        });
      } else {
        self.$log.info("Method: 'processUserValues'. Url: '/phase3save/'",
            "No matches!!! Redirecting to alternative phase", self.tgpd);
        self.topValues = topValues;
        self.leftValues = leftValues;
        self.isGameOver = false;
        self.readyNext = true;
        self.playerGroups = usersValues;
      }
    },
    getFinalResult() {
      let self = this;
      let requestData = {
        "game": this.tgpd.game,
        "team": this.tgpd.team
      };

      this.$axios.post("/phase7/", requestData).then(function(response) {
        if (response.data && response.data["resultedValues"] && response.data["resultedValues"].length > 0) {
          self.$log.info(`Received final results!  ${response.data}. User ${self.tgpd.player}`);
          self.resultedValues = response.data["resultedValues"];
          self.phase2Results = response.data["phase2Results"];
          self.isGameOver = true;
          self.readyNext = true;
        } else {
          setTimeout(self.getFinalResult, 1000);
        }
      }).catch(function (error) {
        self.$log.error("Method: 'getFinalResult'. Url: '/phase7/'. Output: ", error, self.tgpd);
        setTimeout(self.getFinalResult, 1000);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .sc-mb-8 {
    margin-bottom: 8px;
  }

  .sc-w-20 {
    width: 20px;
  }

  .values-block-sorted span {
    margin-top: 0;
    margin-left: 32px;
  }

  .sc-mt-8m {
    margin-top: -8px;
  }

  .values-category {
    padding: 0 8px;
  }

  .values-block-sorted {
    border: 3px solid;
    border-radius: 4px;
    min-height: 30px;
    max-height: 46px;
    color: black;
    width: 300px;
    margin: 8px 0 0;
  }

  .values-block-sorted:hover {
    border-color: var(--bs-secondary);
  }

  .move-item {
    color: #C4C4C4;
  }

  .move-item:hover {
    cursor: pointer;
  }

  .move-item:hover span {
    color: black;
  }

  .move-item:hover .values-block-sorted {
    border: solid 3px #C4C4C4;
  }

  .disabled-area {
    position: absolute;
    width: 100%;
    height: 62%;
    z-index: 10;
    top: 10%;
    bottom: 10%;
  }
</style>
