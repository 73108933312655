<template>
  <div id="sign-in-page" class="row">
    <left-info-panel></left-info-panel>
    <div class="col-6 d-flex justify-content-center align-items-center bg-secondary sc-right-block">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col text-end">
              <p class="head-label sc">{{ t.NoAccount }} <b><a href="#/signup">{{ t.Register }}</a></b></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <div class="card sc">
                <div class="card-body sc">
                  <label class="form-label d-block sc sc-fs-14">{{ t.SignIn }}</label>
                  <h4 class="card-title sc">{{ t.Hello }} :)</h4>
                  <form method="post" action="/accounts/google/login/">
                    <input type="hidden" name="csrfmiddlewaretoken" :value="csrftoken">
                    <button class="btn btn-primary sc-signin-google" type="submit">
                      <i class="icon-google sc"></i><span>{{ t.SignInGoogle }}</span>
                    </button>
                  </form>
                  <hr class="sc-hr">
                  <label class="form-label d-block sc-divider">{{ t.Or }}</label>
                  <form id="signInForm">
                    <label class="form-label d-block sc mt-5">{{ t.Email }}</label>
                    <input v-bind:style="{'border-color':(singInError !== '' && email === '') ? '#FF4D4D': ''}"
                           type="text" class="sc-reg-input" name="login" id="email" v-model="email">
                    <label :class="currentLanguageCode" class="form-label d-block sc password">{{ t.Password }} <span style="color: #C4C4C4">{{ t.PasswordError }}</span></label>
                    <input v-if="showPassword" v-bind:style="{'border-color':(singInError !== '' && !isStrongPassword) ? '#FF4D4D': ''}"
                           type="text" class="sc-reg-input" name="password" id="password" v-model="password">
                    <input v-else v-bind:style="{'border-color':(singInError !== '' && !isStrongPassword) ? '#FF4D4D': ''}"
                           type="password" class="sc-reg-input" name="password" id="password" v-model="password">

                        <a class="icon-wrap sc" href="#" @click.prevent="toggleShow">
                          <i class="fas text-secondary" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                        </a>
                    <div :class="currentLanguageCode" class="d-flex flex-row sc remember">
                      <div class="p-0">
                        <input class="form-check-input" type="checkbox" id="rememberMe" name="remember_me" value="true">
                        <label class="form-check-label sc-ml-10" for="rememberMe">{{ t.RememberMe }}</label>
                      </div>
                      <div class="p-0 ms-auto">
                        <label class="form-check-label"><a href="#/forgot-password/">{{ t.ForgotPassword }}</a></label>
                      </div>
                    </div>

                    <button v-bind:class="signinButtonClassObject" class="btn btn-primary sc-login-button"
                            type="button" @click.self.prevent="login">
                      {{ t.Start }}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div v-show="authError === ''" :class="{invisible: (singInError === '')}" class="row sc-error-block">
            <div class="col d-flex justify-content-center">
              <div class="d-flex alert alert-danger text-start sc" role="alert">
                <i class="fas fa-info-circle"></i>
                <span class="d-inline-block">
                  <strong>{{ singInError }}</strong>
                </span>
              </div>
            </div>
          </div>
          <div v-show="authError !== ''" class="row sc-error-block">
            <div class="col d-flex justify-content-center">
              <div class="d-flex alert alert-danger text-start auth sc" role="alert">
                <span class="d-inline-block">
                  <strong>{{ authError }}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <languages :in-the-game=false></languages>
      <social-links></social-links>
    </div>
  </div>
</template>

<script>
import LeftInfoPanel from "@/components/leftInfoPanel";
import {csrfAjaxMixin} from "@/mixins/csrf";
import SocialLinks from "@/components/socialLinks.vue";
import {i18nMixin} from "@/mixins/i18n";
import Languages from "@/components/languages.vue";

export default {
  name: "sign-in-page",
  components: {Languages, SocialLinks, LeftInfoPanel},
  delimiters: ['[[', ']]'],
  mixins: [csrfAjaxMixin, i18nMixin],
  data: function () {
    return {
      singInError: "",
      authError: "",
      email: "",
      password: "",
      showPassword: false,
    }
  },
  watch: {
    email: function (newEmail, oldEmail) {
      if (newEmail !== "") {
        this.singInError = "";
      }
    },
    password: function (newPassword, oldPassword) {
      if (newPassword !== "" && this.isStrongPassword) {
        this.singInError = "";
      }
    }
  },
  computed: {
    signinButtonClassObject() {
      return {
        disabled: this.email === "" || this.password === ""
      }
    },
    isStrongPassword() {
      return this.password.length >= 6;
    },
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  created() {
    if (this.$route.params.error === "error1") {
      this.authError = this.t.AuthErrorExist;
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    login() {
      if (this.email === "" || this.password === "" ) {
        this.singInError = this.t.SingInErrorEmpty;
      } else if (!this.isStrongPassword) {
        this.singInError = this.t.SingInErrorStrongPassword;
      }
      else {
        let self = this;
        this.$axios.post('/accounts/login/', $("#signInForm").serialize())
            .then(function (response) {
              self.$log.info("Method: 'login'. Url: '/accounts/login/'. Action: signin. Output: ", response);
              if (response.data.location === "/accounts/confirm-email/") {
                self.authError = self.t.AuthErrorActivation;
              } else {
                location.replace(response.data.location);
                window.location.reload(true);
              }
            }).catch(function (error) {
            let errMsg = "";
            if (error.response) {
                errMsg = error.response;
                if (error.response.data.form.errors.length > 0) {
                  self.singInError = error.response.data.form.errors.toString()
                } else {
                  let allErrors = "";
                  ["login", "password"].forEach(function (value, index, array){
                    if (error.response.data.form.fields[value].errors.length > 0) {
                      allErrors += value + ": " + error.response.data.form.fields[value].errors.toString() + "\n";
                    }
                  });
                  self.singInError = allErrors;
                }
              } else if (error.request) {
                errMsg = error.request;
              } else {
                errMsg = error.message;
              }
              self.$log.error("Method: 'login'. Url: '/accounts/login/'. Action: signin. Output: ", errMsg)
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  #sign-in-page {
    height: 100%;
    padding: 20px;
    margin: 0;
  }

  .head-label.sc {
    padding-right: 5px;
    margin: 0;
    color: white;
  }

  .head-label.sc a {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }

  .sc-signin-google {
    width: 300px;
    height: 56px;
    margin-left: 25px;
    background-color: white;
    color: black;
    border-color: #4D78E7;
    border-width: 2px;
    margin-bottom: 30px;
  }

  .sc-signin-google span {
    position: relative;
    top: -6px;
  }

  .icon-google.sc{
    width: 29px;
    height: 28px;
    background:url('~@/assets/google.png') no-repeat;
    background-size: 29px 28px;
    display: inline-block;
    margin-right: 15px;
  }

  .sc-divider {
    position: relative;
    top: -14px;
    left: 45%;
    background-color: white;
    width: 50px;
    padding-left: 13px;
    font-size: 15px;
  }

  .sc-reg-input {
    padding-left: 25px;
    margin-left: 25px;
    width: 300px;
    height: 56px;
    border-radius: 4px;
  }

  .icon-wrap.sc {
    color: #C4C4C4;
    text-decoration: none;
    margin-left: -40px;
  }

  .sc-right-block {
    height: 100%;
    border-radius: 10px;
  }

  .card.sc {
    width: 400px;
    height: 600px;
    margin: 0;
    position: relative;
  }

  .card-body.sc {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    padding: 0 25px;
  }

  .card-title.sc {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-left: 25px;
    margin-bottom: 27px;
  }

  .sc-hr {
    width: 100%;
    margin: 0 0 0 0;
    padding-right: 0;
    padding-left: 0;
    border-color: #C4C4C4;
  }

  .form-label.sc {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 8px;
    margin-left: 25px;
    font-size: 16px;
  }

  .remember.EN {
    font-size: 11px!important;
  }

  .password.EN span {
    font-size: 14px!important;
  }

  @media (min-height: 600px) and (max-height: 760px) {
    .card.sc {
      height: 500px!important;
    }

    .sc-signin-google {
      height: 46px!important;
      margin-bottom: 10px!important;
    }

    .sc-hr {
      margin-top: 15px!important;
      margin-bottom: -15px!important;
    }

    .sc-divider {
      top: 0!important;
    }

    .sc-reg-input {
      height: 46px!important;
    }

    .card-title.sc {
      margin-bottom: 15px!important;
    }

    .sc-signin-google {
      margin-bottom: 15px!important;
    }

    .form-label.sc {
      margin-top: 25px!important;
      margin-bottom: 5px!important;
    }

    .sc-login-button {
      height: 46px!important;
      margin-top: 15px!important;
      margin-left: 25px;
    }

    .alert-danger {
      padding-top: 10px!important;
      padding-bottom: 10px!important;
    }
  }

  @media (min-width: 1024px) and (max-width: 1399px) {
    .card.sc {
      height: 550px;
    }

    .card-title.sc {
      margin-bottom: 20px;
    }

    .sc-signin-google {
      margin-bottom: 25px;
    }

    .form-label.sc {
      margin-top: 20px;
      margin-bottom: 5px;
    }
    .sc-divider {
      font-size: 14px;
    }
  }

  .mt-5 {
    margin-top: 5px!important;
  }

  .sc-ml-10 {
    margin-left: 10px!important;
  }

  .sc-login-button {
    width: 300px;
    height: 56px;
    margin-top: 25px;
    margin-left: 25px;
    border: none;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
  }

  .sc-fs-14 {
    font-size: 14px!important;
  }

  .flex-row.sc {
    margin-left: 25px;
    margin-top: 15px;
    font-size: 14px;
    width: 300px;
  }

  .sc-error-block {
    --bs-danger: #dc3545;
    --bs-danger-rgb: 220,53,69;
  }

  .alert.sc {
    padding-right: 25px;
    padding-left: 25px;
    background: rgb(254,88,88);
    width: 400px;
    margin: 7px 0 0;
  }

  .alert.auth.sc {
    font-size: 12px;
    padding-left: 25px;
    padding-right: 15px;
  }

  .alert.sc i {
    margin-right: 15px;
    color: rgb(255,255,255);
    font-size: 25px;
  }

  .alert.sc span {
    color: rgb(255,255,255);
    position: relative;
  }

</style>
