<template>
  <div class="col d-flex flex-row align-items-center flex-wrap justify-content-start align-items-center p-0">
    <hints :bottom="getByPhase()">
      <template v-slot:content>
        <slot name="hintContent"></slot>
      </template>
    </hints>

    <div class="d-inline-flex float-start justify-content-center align-items-center px-3 position-relative">
      <div class="d-flex justify-content-center align-items-center ts-inner"
           @mouseover="activeList=true;getPlayersStatus();" @mouseleave="customFade">

        <i class="ts-inner-icon" v-bind:class="{ active: activeList }"></i>
        <span class="px-2">{{ playersState.length }}</span>
      </div>
      <div class="d-flex position-absolute ps-container">
        <div class="card sc" v-show="activeList"
             @mouseover="listIn=true;" @mouseleave="listIn=false;">
          <div class="card-header sc border-0 rounded-0 bg-transparent">
            <span class="sc-fs-16">{{ t.Participants }}</span>
          </div>
          <div class="card-body px-3 py-0">
            <div class="row">
              <div class="col py-3">
                <div class="d-flex justify-content-left align-items-center py-1"
                     v-for="item in playersState">
                  <i class="ps-inner-icon col-1" v-bind:class="classObject(item)"></i>
                  <span class="fw-lighter col-11 mx-3">{{ cutLastName(item.name) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span v-bind:class="{ invisible: (readyPlayer === '') }" class="text-left flex-wrap sc-w-300 px-3 py-0">
      {{ readyPlayer }} {{ t.Awaiting }}
    </span>
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import {mapState, mapWritableState, mapActions} from 'pinia';
import {useMainStore} from "@/stores/main";
import Hints from "@/components/hints.vue";
import {i18nMixin} from "@/mixins/i18n";

export default {
  name: 'players-state',
  mixins: [i18nMixin],
  components: {Hints},
  props: ["currentPhase"],
  data() {
    return {
      playersState: [],
      readyPlayer: "",
      currentPlayerInfoIndex: -1,
      screenX: 0,
      screenY: 0,
      activeHint: false,
      activeList: false,
      listIn: false,
    }
  },
  watch: {
    playersState(...args) {
      this.debouncedWatch(...args);
    }
  },
  computed: {
    ...mapActions(useMainStore, ['gamePhase']),
    ...mapState(useMainStore, ["tgpd"]),
    ...mapWritableState(useMainStore, ["playersCount"])
  },
  created() {
    this.getPlayersStatus();
    // TODO make interval less resource consumption. 1 sec is to frequent. Trying 3 sec!
    // setTimeout(this.getPlayersStatus, 3000);
    this.debouncedWatch = debounce((newState, oldState) => {
      let self = this;
      if (oldState.length === 0) {
        return;
      }
      for (let i = 0; i < newState.length; i++) {
        if(newState[i].ready !== oldState[i].ready && self.tgpd.player !== newState[i].name) {
          self.readyPlayer = newState[i].name;
          self.$log.info("Method: created. Action: Player state updated. Output: ", self.readyPlayer);
          setTimeout(()=>{
            self.readyPlayer = "";
          }, 3000);
          break;
        }
      }
    }, 500);
  },
  beforeUnmount() {
    this.debouncedWatch.cancel();
  },
  methods: {
    getByPhase() {
      return (+this.currentPhase === 7)?"10%":"16%";
    },
    cutLastName(fullName) {
      let result = ""
      if (fullName === this.tgpd.player) {
        result = this.t.I;
      } else {
        let [fn, ln] = fullName.split(" ");
        result = `${fn} ${ln[0]}.`;
      }
      return result;
    },
    customFade() {
      let self = this;
      setTimeout(() => {
        if (!self.listIn) {
          self.activeList = false;
        } else {
          self.customFade();
        }
      }, 1000)
    },
    classObject(item) {
      return {
        "check": !item.hasOwnProperty("decision") && item.ready,
        "plus": item.hasOwnProperty("decision") && item.decision === 1,
        "minus": item.hasOwnProperty("decision") && item.decision === 0
      }
    },
    getPlayersCount() {
      return this.playersState.length;
    },
    getPlayersStatus(){
      if (+this.currentPhase !== this.gamePhase) {
        return;
      }
      let self = this;
      if (this.tgpd.game && this.tgpd.team) {
        let requestData = {
          "game": this.tgpd.game,
          "team": this.tgpd.team,
          "currentPhase": this.currentPhase
        };
        this.$axios.post("/players-state/", requestData).then(function(response) {
          if (response.data.length > 0) {
            self.playersState = response.data;
            self.playersCount = self.playersState.length;
          }
        }).catch(function (error) {
          self.$log.error("Method: 'getPlayersStatus'. Url: '/players-state/'. Output: ", error);
        }).finally(
            // () => setTimeout(self.getPlayersStatus, 3000)
        );
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.ts-inner {
  margin-left: 5px;
  width: 77px;
  height: 40px;
  border-radius: 8px;
  background: var(--bs-white);
  padding: 0;
}

.ts-inner-icon {
  height: 21px;
  width: 27px;
  background:url('~@/assets/ts_inactive.png') no-repeat;
  background-size: 27px 21px;
  display: inline-block;
}

.ts-inner-icon.active {
  height: 21px;
  width: 27px;
  background:url('~@/assets/ts_active.png') no-repeat;
  background-size: 27px 21px;
  display: inline-block;
}

.ts-inner.active {
  background: var(--bs-white);
}

.ts-inner:hover {
  background: var(--bs-white);
  cursor: pointer;
}

.card.sc {
  font-family: 'Unbounded';
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.5px;
  min-width: 230px;
  max-height: 350px;
  overflow-y: scroll;
  border: none;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 8px 8px 20px 0px rgba(0, 0, 0, 0.25);
}

.card-header.sc {
  padding-top: 15px;
  padding-bottom: 0;
}

.sc-w-300 {
  width: 300px;
}

.ps-inner-icon {
  height: 16px;
  width: 16px;
  background:url('~@/assets/ps_uncheck.png') no-repeat;
  background-size: 16px 16px;
}

.ps-inner-icon.check {
  background:url('~@/assets/ps_check.png') no-repeat;
  background-size: 16px 16px;
}

.ps-inner-icon.plus {
  background:url('~@/assets/ps_plus.png') no-repeat;
  background-size: 16px 16px;
}

.ps-inner-icon.minus {
  background:url('~@/assets/ps_minus.png') no-repeat;
  background-size: 16px 16px;
}

.sc-fs-16 {
  font-size: 16px;
}

.ps-container {
  left: 20px;
  bottom: 50px;
}
</style>
