import initData from "../components/data/initData";
import { defineStore } from 'pinia'
import axios from "axios";
import {currentLanguageCode} from "@/locale/language";

axios.interceptors.response.use((response) => response, async (inst) => {

    let config = (inst && inst.config) ? inst.config : {}
    config.currentRetryAttempt = config.currentRetryAttempt || 0;
    const retry = 5;
    const retryDelay = 1000;
    const retryRanges = [
        // 1xx - Retry (Informational, request still processing)
        // 2xx - Do not retry (Success)
        // 3xx - Do not retry (Redirect)
        // 4xx - Do not retry (Client errors)
        // 429 - Retry ("Too Many Requests")
        // 5xx - Retry (Server errors)
        [100, 199],
        [429, 429],
        [500, 599],
    ];

    if (inst.response) {
        let isInRange = false;
        for (const [min, max] of retryRanges) {
            if (inst.response.status >= min && inst.response.status <= max) {
                isInRange = true;
                break;
            }
        }
        if (isInRange && config.currentRetryAttempt < retry) {
            config.currentRetryAttempt += 1;
            inst.config = config;
            console.warn(`Retrying ${config.url} with status code ${inst.response.status}. 
            Attempt ${config.currentRetryAttempt}/${retry}`);
            const onBackoffPromise= new Promise(function (resolve, ) {
                setTimeout(resolve, retryDelay)
            });

            return Promise.resolve()
                .then(async () => onBackoffPromise)
                .then(async () => axios.request(inst.config));
        }
    }

    return Promise.reject(inst);
})

export const useMainStore = defineStore('main', {
    state: () => (initData),
    getters: {
        tgpd: (state) => {
            return {
                team: state.team,
                game: state.game,
                player: state.player,
                description: state.description
            };
        },
        gameHash: (state) => {
            return btoa(state.game)
                .replace(/\+/g, '-') // Convert '+' to '-'
                .replace(/\//g, '_') // Convert '/' to '_'
                .replace(/=+$/, '');
        }
    },
    actions: {
        gamePhase() {
            let states = [
                this.isSection1,
                this.isSection2,
                false,
                this.isSection4,
                false,
                this.isSection6,
                this.isSection7
            ];
            return (this.isSection21) ? 21 : states.findIndex(item=>item) + 1;
        },
        fetchPhase1() {
            let self = this;
            let cfg = {
                "headers": {
                    "Accept-Language": currentLanguageCode.value
                }
            }
            axios.get("/current-state/phase1/", cfg).then(function(response) {
                self.team = response.data["team"];
                self.game = response.data["game"];
                self.player = response.data["player"];
                self.description = response.data["description"];
                self.groups = response.data["groups"];

                self.isSection2 = false;
                self.isSection4 = false;
                self.isSection6 = false;
                self.isSection7 = false;
                self.isSection1 = true;
            }).catch(function (error) {
                console.log.error("Method: 'fetchPhase1'. Url: '/current-state/phase1/'", error);
            });
        },
        fetchPhase2() {
            let self = this;
            axios.get("/current-state/phase2/").then(function(response) {
                self.team = response.data["team"];
                self.game = response.data["game"];
                self.player = response.data["player"];
                self.description = response.data["description"];
                self.top10 = response.data["top10"];
                self.firstClick = response.data["firstClick"];
                self.isSection1 = false;
                self.isSection4 = false;
                self.isSection6 = false;
                self.isSection7 = false;
                self.isSection2 = true;
            }).catch(function (error) {
                console.log.error("Method: 'fetchPhase2'. Url: '/current-state/phase2/'", error);
            });
        },
        fetchPhase21() {
            let self = this;
            axios.get("/current-state/phase21/").then(function(response) {
                self.team = response.data["team"];
                self.game = response.data["game"];
                self.player = response.data["player"];
                self.description = response.data["description"];
                self.playerGroups = response.data["playerGroups"];
                self.top10 = response.data["top10"];
                self.firstClick = response.data["firstClick"];
                self.isSection1 = false;
                self.isSection2 = false;
                self.isSection4 = false;
                self.isSection6 = false;
                self.isSection7 = false;
                self.isSection21 = true;
            }).catch(function (error) {
                console.log.error("Method: 'fetchPhase21'. Url: '/current-state/phase21/'", error);
            });
        },
        fetchPhase4() {
            let self = this;
            axios.get("/current-state/phase4/").then(function(response) {
                self.team = response.data["team"];
                self.game = response.data["game"];
                self.player = response.data["player"];
                self.description = response.data["description"];
                self.topValues = response.data["top"];
                self.leftValues = response.data["left"];
                self.firstClick = response.data["firstClick"];
                if (self.firstClick) {
                    for (let i in self.leftValues) {
                        self.leftValuesMock.push({title: self.leftValues[i].title});
                    }
                } else {
                    for (let i = self.topValues.length + 1; i <= 10; i++) {
                        self.leftValuesMock.push({title: i});
                    }
                }
                self.isSection1 = false;
                self.isSection2 = false;
                self.isSection6 = false;
                self.isSection7 = false;
                self.isSection4 = true;
            }).catch(function (error) {
                console.log.error("Method: 'fetchPhase4'. Url: '/current-state/phase4/'", error);
            });
        },
        fetchPhase6() {
            let self = this;
            axios.get("/current-state/phase6/").then(function(response) {
                self.team = response.data["team"];
                self.game = response.data["game"];
                self.player = response.data["player"];
                self.description = response.data["description"];
                self.topValuesPreArguable = response.data["topValuesPreArguable"];
                self.arguableValues = response.data["arguableValues"];
                self.firstClick = response.data["firstClick"];
                self.isSection1 = false;
                self.isSection2 = false;
                self.isSection4 = false;
                self.isSection7 = false;
                self.isSection6 = true;
            }).catch(function (error) {
                console.log.error("Method: 'fetchPhase6'. Url: '/current-state/phase6/'", error);
            });
        },
        fetchPhase7() {
            let self = this;
            axios.get("/current-state/phase7/").then(function(response) {
                self.team = response.data["team"];
                self.game = response.data["game"];
                self.player = response.data["player"];
                self.description = response.data["description"];
                self.resultedValues = response.data["resultedValues"];
                self.phase2Results = response.data["phase2Results"];
                self.isSection1 = false;
                self.isSection2 = false;
                self.isSection4 = false;
                self.isSection6 = false;
                self.isSection7 = true;
            }).catch(function (error) {
                console.log.error("Method: 'fetchPhase7'. Url: '/current-state/phase7/'", error);
            });

            axios.get("/current-state/vote/").then(function(response) {
                self.voted = response.data["voted"];
                self.unvoted = response.data["unvoted"];
                self.voteResult = response.data["voteResult"];
                self.once = response.data["once"];
            }).catch(function (error) {
                console.log.error("Method: 'fetchPhase7'. Url: '/current-state/vote/'", error);
            });
        }
    },
})