import { t, availableLanguages, setLanguage, currentLanguageCode } from '@/locale/language'

export const i18nMixin = {
    data: function () {
        return {
            t: t,
            availableLanguages: availableLanguages,
            currentLanguageCode: currentLanguageCode
        }
    }
}