<template>
  <a @click.prevent="toggle" :class="{ game: inTheGame}"
     class="d-flex li-row position-absolute rounded-3 justify-content-center align-items-center" href="#">
    {{ currentLanguageCode }}
  </a>
</template>

<script>
import {csrfAjaxMixin} from "@/mixins/csrf";
import {i18nMixin} from "@/mixins/i18n";

export default {
  name: "languages",
  mixins: [csrfAjaxMixin, i18nMixin],
  props: ["inTheGame"],
  methods: {
    toggle() {
      this.changeLanguage((this.currentLanguageCode === "UA")?"EN":"UA");
      if (this.inTheGame) {
        window.location.reload();
      }
    }
  }
}
</script>

<style scoped>

  .li-row {
    background-color: white;
    display: block;
    width: 40px;
    height: 40px;
    top: 4%;
    right: 2.5%;
  }

  .game.li-row {
    top: 7%!important;
    right: 1.5%!important;
    display: none!important;
  }

  .li-icon {
    height: 24px;
    width: 24px;
    background-size: 24px 24px;
    display: inline-block;
  }

</style>