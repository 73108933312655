<template>
  <div id="page6" class="h-100">
    <header-title :displayStepInfo="true">
      <template  v-slot:company>
        <company-info></company-info>
      </template>
      <template v-slot:stepInfo>{{ t.OurCommonSense }}</template>
      <template  v-slot:step>4</template>
      <template  v-slot:text>{{ topValuesPreArguable.length }}/10 {{ t.OrderFinal10SensesTitle }}</template>
    </header-title>

    <div class="row d-flex flex-grow-1 step-main-row middle" v-bind:class="{ 'sc-disabled-area': !showReady }">
      <div class="col-auto d-flex flex-column flex-grow-1 align-items-center values-category my-auto">
        <div class="row row-cols-1 d-flex">
          <div class="col d-flex flex-column sc-vc-scroll">
            <div class="row text-center d-flex values-block-top"
                 v-for="(value, index) in topValuesPreArguable.slice(0, 3)" :key="value.id" :rating="value.rating">
              <div class="col-11 d-flex flex-grow-1 justify-content-center align-items-center p-0">
                <span class="m-0">{{ value.title }}</span>
              </div>
            </div>
            <div class="row text-center d-flex values-block-top"
                 v-for="(value, index) in topValuesPreArguable.slice(3)" :key="value.id" :rating="value.rating">
              <div class="col-11 d-flex flex-grow-1 justify-content-center align-items-center p-0">
                <span class="m-0">{{ value.title }}</span>
              </div>
            </div>
            <draggable v-model="arguableValues">
              <transition-group>
                <div class="row row-cols-2 values-block-wrapper"
                     v-for="(value, index) in arguableValues" :key="value.id" :rating="value.rating">
                  <div class="col-1 d-flex align-items-center p-0">
                    <span class="text-center sc-w-20 text-secondary">{{ topValuesPreArguable.length + index + 1 }}</span>
                  </div>
                  <div class="col-11 p-0">
                    <div class="row text-center d-flex values-block-sorted"
                         @mouseover="$set(highlight, index, true)"
                         @mouseout="$set(highlight, index, false)">
                      <div class="col-11 d-flex flex-grow-1 justify-content-center align-items-center p-0">
                        <span class="sc-ml-32">{{ value.title }}</span>
                      </div>
                      <div class="col-1 d-flex p-0">
                        <div class="row row-cols-1 m-0">
                          <div class="col m-0 p-0">
                            <i class="fas fa-angle-up text-light"></i>
                          </div>
                          <div class="col p-0 sc-mt-10m">
                            <i class="fas fa-angle-down text-light"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-cols-3 step-main-row bottom">
      <players-state :current-phase="6">
        <template v-slot:hintContent>
          <p v-html="t.OrderFinal10SensesHint"></p>
        </template>
      </players-state>
      <div class="col d-flex align-items-center"></div>
      <div class="col d-flex align-items-center">
        <div class="row d-flex flex-grow-1">
          <div v-bind:class="{ invisible: !showWait  }" class="col-9 d-flex justify-content-center align-items-center">
            <span>{{ t.AwaitingOtherPlayers }}</span>
          </div>
          <div class="col-3 d-flex justify-content-end p-0">
            <next-button @goto="onGoto" :ready-next="readyNext">
              <template v-slot:content v-if="showReady">
                <span>{{ t.Done }}</span>
              </template>
              <template v-slot:content v-else>
                <span>{{ t.Next }}</span>
                <i class="fas fa-arrow-right sc"></i>
              </template>
            </next-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import playersState from "./playersState";
import companyInfo from "./companyInfo";
import headerTitle from "./headerTitle";
import nextButton from "./nextButton";
import draggable from 'vuedraggable';
import {mapState, mapWritableState, mapActions} from 'pinia';
import {useMainStore} from "@/stores/main";
import {i18nMixin} from "@/mixins/i18n";
// import s6AppMock from "@/components/data/S6AppMock";

export default {
  name: 'section6',
  components: {
    playersState,
    companyInfo,
    headerTitle,
    nextButton,
    draggable
  },
  mixins: [i18nMixin],
  data: function () {
    return {
      readyNext: true,
      showReady: true,
      showWait: false,
      postPhase6: false,
      once: false,
      highlight: [],
    }
  },
  computed: {
    ...mapActions(useMainStore, ['fetchPhase6']),
    ...mapState(useMainStore, ["tgpd", "topValuesPreArguable", "firstClick"]),
    ...mapWritableState(useMainStore, ["isSection6", "isSection7", "resultedValues", "phase2Results", "arguableValues"])
  },
  created() {
    this.highlight = Array(this.arguableValues.length).fill(false);
    if (!this.topValuesPreArguable.length || !this.tgpd.game) {
      this.fetchPhase6;
    }
  },
  watch: {
    firstClick: function (val) {
      if (val === true) {
        this.showReady = false;
        this.readyNext = false;
        this.postPhase6 = true;
        setTimeout(this.getFinalResult, 1000);
      }
    }
  },
  methods: {
    onGoto() {
      let self = this;

      if (self.readyNext && !self.showReady) {
        this.isSection6 = false;
        this.isSection7 = true;
        this.$router.push({ path: '/phase7' })
        return;
      }

      if (self.postPhase6) {
        self.showWait = true;
        setTimeout(()=> self.showWait = false, 3000);
        return;
      }

      let requestData = {
        "game": this.tgpd.game,
        "team": this.tgpd.team,
        "player": this.tgpd.player,
        "left": this.arguableValues
      };

      this.$axios.post("/phase6/", requestData).then(function(response) {
        setTimeout(self.getFinalResult, 1000);
      }).catch(function (error) {
        self.$log.error("Method: 'onGoto'. Url: '/phase6/'. Output: ", error, self.tgpd);
      });

      self.showReady = false;
      self.readyNext = false;
      self.postPhase6 = true;

    },

    getFinalResult: function() {
      let self = this;
      let requestData = {
        "game": this.tgpd.game,
        "team": this.tgpd.team
      };

      this.$axios.post("/phase7/", requestData).then(function(response) {
        if (response.data && response.data["resultedValues"] && response.data["resultedValues"].length > 0) {
          self.$log.info("Method: 'getFinalResult'. Url: '/phase7/'", response.data, self.tgpd);
          self.resultedValues = response.data["resultedValues"];
          self.phase2Results = response.data["phase2Results"];
          self.readyNext = true;
        } else {
          if (!self.once) {
            self.showWait = true;
            self.alertNextMessage = self.t.AwaitingOtherPlayers;
            setTimeout(() => self.showWait = false, 3000);
            self.once = true;
          }
          setTimeout(self.getFinalResult, 1000);
        }
      }).catch(function (error) {
        self.$log.error("Method: 'getFinalResult'. Url: '/phase7/'. Output: ", error, self.tgpd);
        setTimeout(self.getFinalResult, 1000);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .values-category {
    padding: 0 8px;
  }

  .values-block-top {
    border: 3px solid var(--bs-white);
    border-radius: 4px;
    min-height: 45px;
    max-height: 56px;
    color: black;
    width: 300px;
    margin: 0 0 -3px;
    height: 9%;
  }

  .values-block-sorted {
    border: 3px solid var(--bs-white);
    border-radius: 4px;
    margin: 0;
    min-height: 45px;
    max-height: 56px;
    color: var(--bs-dark);
    width: 300px;
  }

  .values-block-wrapper {
    margin: 4px 0 4px -28px;
    color: black;
  }

  .values-block-wrapper:hover {
    cursor: pointer;
  }

  .values-block-wrapper:hover .values-block-sorted {
    border: solid 3px var(--bs-secondary);
  }

  .values-block-wrapper:hover span {
    color: black;
  }

  .values-block-wrapper:hover span.text-white {
    color: var(--bs-secondary);
  }

  .sc-vc-scroll {
    padding: 0 12px 0 30px;
    max-height: 482px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .sc-ml-32 {
    margin: 0 0 0 32px;
  }

  .sc-w-20 {
    width: 20px;
  }

  .sc-mt-10m {
    margin: -10px 0 0;
  }

  .disabled-area {
    position: absolute;
    width: 100%;
    height: 70%;
    z-index: 10;
    top: 10%;
    bottom: 10%;
  }
</style>
