<template>
  <div id="sign-up-page" class="row">
    <left-info-panel></left-info-panel>
    <div class="col-6 d-flex justify-content-center align-items-center bg-secondary sc-right-block">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col text-end">
              <p class="head-label sc">Вже в системі? <b><a href="#/signin">Увійти</a></b></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <div class="card sc">
                <div class="card-body sc">
                  <label class="form-label d-block sc sc-fs-14">Реєстрація</label>
                  <h4 class="card-title sc">Хелоу!</h4>
                  <form method="post" action="/accounts/google/login/">
                    <input type="hidden" name="csrfmiddlewaretoken" :value="csrftoken">
                    <button class="btn btn-primary sc-signup-google" type="submit">
                      <i class="icon-google sc"></i><span>Google</span>
                    </button>
                  </form>

                  <hr class="sc-hr">
                  <label class="form-label d-block sc-divider">або</label>
                  <form id="signUpForm">
                    <label class="form-label d-block sc mt-5">Пошта</label>
                    <input v-bind:style="{'border-color':(singUpError !== '' && email === '') ? '#FF4D4D': ''}"
                           type="text" class="sc-reg-input" name="email" id="email" v-model="email">
                    <label class="form-label d-block sc">Пароль <span style="color: #C4C4C4">6 символів мінімум</span></label>
                    <input v-if="showPassword" v-bind:style="{'border-color':(singUpError !== '' && !isStrongPassword) ? '#FF4D4D': ''}"
                           type="text" class="sc-reg-input" name="password1" id="password" v-model="password">
                    <input v-else v-bind:style="{'border-color':(singUpError !== '' && !isStrongPassword) ? '#FF4D4D': ''}"
                           type="password" class="sc-reg-input" name="password1" id="password" v-model="password">

                        <a class="icon-wrap sc" href="#" @click.prevent="toggleShow">
                          <i class="fas" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                        </a>
                    <p class="sc-form-p">
                      Натискаючи <span><b>РОЗПОЧАТИ</b></span>, я приймаю
                      <a class="text-secondary text-decoration-none" target="_blank" href="/terms/">умови використання</a> та погоджуюся з
                      <a class="text-secondary text-decoration-none" target="_blank" href="/policy/">політикою конфіденційності</a>.
                    </p>
                    <button v-bind:class="signupButtonClassObject" class="btn btn-primary sc-login-button" type="button" @click.self.prevent="login">
                      Розпочати
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div v-show="authError === ''" :class="{invisible: (singUpError === '')}" class="row sc-error-block">
            <div class="col d-flex justify-content-center">
              <div class="d-flex alert alert-danger text-start sc" role="alert">
                <i class="fas fa-info-circle"></i>
                <span class="d-inline-block">
                  <strong>{{ singUpError }}</strong>
                </span>
              </div>
            </div>
          </div>
          <div v-show="authError !== ''"  class="row sc-error-block">
            <div class="col d-flex justify-content-center">
              <div class="d-flex alert alert-danger text-start auth sc" role="alert">
                <span class="d-inline-block">
                  <strong>{{ authError }}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <social-links></social-links>
    </div>
  </div>
</template>

<script>
import LeftInfoPanel from "@/components/leftInfoPanel";
import {csrfAjaxMixin} from "@/mixins/csrf";
import SocialLinks from "@/components/socialLinks.vue";

export default {
  name: "sign-up-page",
  components: {SocialLinks, LeftInfoPanel},
  delimiters: ['[[', ']]'],
  mixins: [csrfAjaxMixin],
  data: function () {
    return {
      singUpError: "",
      authError: "",
      email: "",
      password: "",
      showPassword: false,
    }
  },
  watch: {
    email: function (newEmail, oldEmail) {
      if (newEmail !== "") {
        this.singUpError = "";
      }
    },
    password: function (newPassword, oldPassword) {
      if (newPassword !== "" && this.isStrongPassword) {
        this.singUpError = "";
      }
    }
  },
  computed: {
    signupButtonClassObject() {
      return {
        disabled: this.email === "" || this.password === ""
      }
    },
    isStrongPassword() {
      return this.password.length >= 6;
    },
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    }
  },
  created() {
    if (this.$route.params.error === "error1") {
      this.authError = "Акаунт з такою e-mail адресою уже існує.\n" +
          "Увійдіть у свій акаунт за допомогою пошти та пароля.";
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    login() {
      if (this.email === "" || this.password === "" ) {
        this.singUpError = "Заповніть порожні поля";
      } else if (!this.isStrongPassword) {
        this.singUpError = "Пароль має бути більше 6 символів";
      }
      else {
        let self = this;
        this.$axios.post('/accounts/signup/', $("#signUpForm").serialize())
             .then(function (response) {
                self.$log.info("Method: 'login'. Url: '/accounts/signup/'. Output: ", response);
                self.$router.push({ path: '/email-confirmation' })
             }).catch(function (error) {
                let errMsg = "";
                if (error.response) {
                  errMsg = error.response;
                  if (error.response.data.form.errors.length > 0) {
                    self.singUpError = error.response.data.form.errors.toString()
                  } else {
                    let allErrors = "";
                    ["email", "password1"].forEach(function (value, index, array){
                      if (error.response.data.form.fields[value].errors.length > 0) {
                        allErrors += value + ": " + error.response.data.form.fields[value].errors.toString() + "\n";
                      }
                    });
                    self.singUpError = allErrors;
                  }
                } else if (error.request) {
                  errMsg = error.request;
                } else {
                  errMsg = error.message;
                }
                self.$log.error("Method: 'login'. Url: '/accounts/signup/'. Output: ", errMsg);
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  #sign-up-page {
    height: 100%;
    padding: 20px;
    margin: 0;
  }

  .head-label.sc {
    padding-right: 5px;
    margin: 0;
    color: white;
  }

  .head-label.sc a {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }

  .sc-signup-google {
    width: 300px;
    height: 56px;
    margin-left: 25px;
    background-color: white;
    color: black;
    border-color: #4D78E7;
    border-width: 2px;
    margin-bottom: 30px;
  }

  .sc-signup-google span {
    position: relative;
    top: -6px;
  }

  .icon-google.sc{
    width: 29px;
    height: 28px;
    background:url('~@/assets/google.png') no-repeat;
    background-size: 29px 28px;
    display: inline-block;
    margin-right: 15px;
  }

  .sc-divider {
    position: relative;
    top: -14px;
    left: 45%;
    background-color: white;
    width: 50px;
    padding-left: 13px;
    margin: 0;
    font-size: 15px;
  }

  .sc-reg-input {
    padding-left: 25px;
    margin-left: 25px;
    width: 300px;
    height: 56px;
    border-radius: 4px;
  }

  .icon-wrap.sc {
    color: #C4C4C4;
    text-decoration: none;
    margin-left: -40px;
  }

  .sc-right-block {
    height: 100%;
    border-radius: 10px;
  }

  .card.sc {
    width: 400px;
    height: 600px;
    margin: 0;
    position: relative;
  }

  .card-body.sc {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    padding: 0 25px;
  }

  .card-title.sc {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-left: 25px;
    margin-bottom: 15px;
  }

  .sc-hr {
    width: 100%;
    margin: 0 0 0 0;
    padding-right: 0;
    padding-left: 0;
    border-color: #C4C4C4;
  }

  .form-label.sc {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: 25px;
    font-size: 16px;
  }

  .sc-form-p {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    margin-top: 15px;
    padding-left: 25px;
    margin-bottom: 25px;
  }

  .sc-form-p span {
    font-weight: 700;
    margin-left: 3px;
  }

  .sc-login-button {
    width: 300px;
    height: 56px;
    margin-left: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
  }

  @media (min-height: 600px) and (max-height: 760px) {
    .card.sc {
      height: 520px!important;
    }

    .sc-signup-google {
      height: 46px!important;
      margin-bottom: 10px!important;
    }

    .sc-hr {
      margin-top: 10px!important;
      margin-bottom: -20px!important;
    }

    .sc-divider {
      top: 6px!important;
    }

    .sc-reg-input {
      height: 46px!important;
    }

    .card-title.sc {
      margin-bottom: 15px!important;
      font-size: 18px!important;
    }

    .sc-signup-google {
      margin-bottom: 15px!important;
    }

    .form-label.sc {
      margin-top: 25px!important;
      margin-bottom: 5px!important;
    }

    .sc-form-p {
      margin-bottom: 5px!important;
      font-size: 12px!important;
    }

    .sc-login-button {
      height: 46px!important;
      margin-top: 5px!important;
      margin-left: 25px;
    }

    .alert-danger {
      padding-top: 10px!important;
      padding-bottom: 10px!important;
    }
  }

  @media (min-width: 1024px) and (max-width: 1399px) {
    .card.sc {
      height: 570px;
    }

    .card-title.sc {
      margin-bottom: 20px;
    }

    .sc-signup-google {
      margin-bottom: 25px;
    }

    .form-label.sc {
      margin-top: 20px;
      margin-bottom: 5px;
    }

    .sc-form-p {
      margin-bottom: 15px;
    }
  }

  .sc-fs-14 {
    font-size: 14px!important;
  }

  .mt-5 {
    margin-top: 5px!important;
  }

  .sc-login-button.active {
    background-color: #C4C4C4;
    color: white;
  }

  .sc-login-button.active:hover {
    background-color: #F1F1F1;
    color: black;
  }

  .sc-error-block {
    --bs-danger: #dc3545;
    --bs-danger-rgb: 220,53,69;
  }

  .alert.sc {
    padding-right: 25px;
    padding-left: 25px;
    background: rgb(254,88,88);
    width: 400px;
    margin: 7px 0 0;
  }

  .alert.auth.sc {
    font-size: 12px;
    padding-left: 25px;
    padding-right: 15px;
  }

  .alert.sc i {
    margin-right: 15px;
    color: rgb(255,255,255);
    font-size: 25px;
  }

  .alert.sc span {
    color: rgb(255,255,255);
    position: relative;
  }

</style>
