<template>
  <div id="reset-password-page" class="row">
    <left-info-panel></left-info-panel>
    <div class="col-6 d-flex justify-content-center align-items-center bg-secondary sc-right-block">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <div class="card sc">
                <div v-show="!isConfirmed" class="card-body sc">
                  <h4 class="card-title sc">Новий пароль</h4>
                  <label class="d-block sc-helper sc-fs-14">Введіть бажаний пароль</label>
                  <form id="resetPasswordForm">
                    <label class="form-label d-block sc">Пароль</label>
                    <input v-bind:style="{'border-color':(resetPasswordError !== '' && !isStrongPassword) ? '#FF4D4D': ''}"
                           type="password" class="sc-password-input" name="password1" v-model="password">
                    <input type="hidden" class="sc-password-input" name="password2" v-model="password">
                    <button v-bind:class="resetPasswordButtonClassObject" class="btn btn-primary sc-confirm-button"
                            type="button" @click.self.prevent="confirm">
                      Підтвердити
                    </button>
                  </form>
                </div>
                <div v-show="isConfirmed" class="card-body sc check">
                  <h4 class="card-title sc">Пароль змінено</h4>
                  <label class="d-block sc-helper sc-fs-14">
                    Тепер, коли твій пароль було успішно змінено, ти можеш повернутися до найважливішого
                  </label>
                  <hr class="sc-hr">
                  <label class="form-label sc">Справді? <a href="#/signin/">Увійти</a></label>
                </div>
              </div>
            </div>
          </div>
          <div :class="{invisible: (resetPasswordError === '')}" class="row sc-error-block">
            <div class="col d-flex justify-content-center">
              <div class="d-flex alert alert-danger text-start sc" role="alert">
                <i class="fas fa-info-circle"></i>
                <span class="d-inline-block">
                  <strong>{{ resetPasswordError }}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <social-links></social-links>
    </div>
  </div>
</template>

<script>
import LeftInfoPanel from "@/components/leftInfoPanel";
import {csrfAjaxMixin} from "@/mixins/csrf";
import SocialLinks from "@/components/socialLinks.vue";

export default {
  name: "reset-password-page",
  components: {SocialLinks, LeftInfoPanel},
  delimiters: ['[[', ']]'],
  mixins: [csrfAjaxMixin],
  data: function () {
    return {
      resetPasswordError: "",
      password: "",
      isConfirmed: false
    }
  },
  watch: {
    password: function (newPassword, oldPassword) {
      if (newPassword !== "") {
        this.resetPasswordError = "";
      }
    }
  },
  computed: {
    resetPasswordButtonClassObject() {
      return {
        disabled: this.password === ""
      }
    },
    isStrongPassword() {
      return this.password.length >= 6;
    },
  },
  methods: {
    confirm() {
      if (this.password === "") {
        this.resetPasswordError = "Заповніть поле Пароль";
      } else if (!this.isStrongPassword) {
        this.resetPasswordError = "Пароль має бути більше 6 символів";
      }
      else {
        let self = this;

        this.$axios.post('/accounts/password/reset/key/' + this.$route.params.key + '/', "")
            .then(function (response) {
              self.$log.info("Method: 'confirm'. Url: '/accounts/password/reset/key/'. " +
                  "Action: reset password. Output: ", response);
              let uidb36 = self.$route.params.key.split("-")[0]

              this.$axios.post('/accounts/password/reset/key/' + uidb36 + '-set-password/', $("#resetPasswordForm").serialize())
                  .then(function (response) {
                    self.$log.info("Method: 'confirm'. Url: '/accounts/password/reset/key/***-set-password/'. " +
                        "Action: reset password. Output: ", response);
                    self.isConfirmed = true;
                  })
                  .catch(function (error) {
                    let errMsg = "";
                    if (error.response) {
                      errMsg = error.response;
                      if (error.response.data.form.errors.length > 0) {
                        self.resetPasswordError = error.response.data.form.errors.toString()
                      } else {
                        let allErrors = "";
                        ["login", "password"].forEach(function (value, index, array){
                          if (error.response.data.form.fields[value].errors.length > 0) {
                            allErrors += value + ": " + error.response.data.form.fields[value].errors.toString() + "\n";
                          }
                        });
                        self.resetPasswordError = allErrors;
                      }
                    } else if (error.request) {
                      errMsg = error.request;
                    } else {
                      errMsg = error.message;
                    }
                    self.$log.error("Method: 'confirm'. Url: '/accounts/password/reset/key/***-set-password/'. " +
                        "Action: reset password. Output: ", errMsg);
                  });
            })
            .catch(function (error) {
              if (error !== undefined) {
                let errMsg = "";
                if (error.response) {
                  errMsg = error.response;
                  if (error.response.data.form.errors.length > 0) {
                    self.resetPasswordError = error.response.data.form.errors.toString()
                  } else {
                    let allErrors = "";
                    ["login", "password"].forEach(function (value, index, array) {
                      if (error.response.data.form.fields[value].errors.length > 0) {
                        allErrors += value + ": " + error.response.data.form.fields[value].errors.toString() + "\n";
                      }
                    });
                    self.resetPasswordError = allErrors;
                  }
                } else if (error.request) {
                  errMsg = error.request;
                } else {
                  errMsg = error.message;
                }
                self.$log.error("Method: 'confirm'. Url: '/accounts/password/reset/key/'. " +
                    "Action: reset password. Output: ", errMsg);
              }
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  #reset-password-page {
    height: 100%;
    padding: 20px;
    margin: 0;
  }

  .sc-password-input {
    padding-left: 25px;
    margin-left: 25px;
    width: 300px;
    height: 56px;
    border-radius: 4px;
  }

  .sc-right-block {
    height: 100%;
    border-radius: 10px;
  }

  .card.sc {
    width: 400px;
    height: 365px;
    margin: 0;
    position: relative;
  }

  .card-body.sc {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    padding: 45px 25px 10px;
  }

  .card-title.sc {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-left: 25px;
    margin-bottom: 10px;
  }

  .check .card-title.sc {
    margin-bottom: 35px;
  }

  .sc-helper {
    margin-left: 25px;
  }

  .form-label.sc {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 8px;
    margin-left: 25px;
    font-size: 16px;
  }

  .sc-confirm-button {
    width: 300px;
    height: 56px;
    margin-top: 25px;
    margin-left: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
  }

  .sc-hr {
    width: 100%;
    margin: 45px 0 10px;
    padding-right: 0;
    padding-left: 0;
    border-color: #C4C4C4;
  }

  .sc-fs-14 {
    font-size: 14px!important;
  }

  .sc-confirm-button.active {
    background-color: #C4C4C4;
    color: white;
  }

  .sc-confirm-button.active:hover {
    background-color: #F1F1F1;
    color: black;
  }

  .sc-error-block {
    --bs-danger: #dc3545;
    --bs-danger-rgb: 220,53,69;
  }

  .alert.sc {
    padding-right: 25px;
    padding-left: 25px;
    background: rgb(254,88,88);
    width: 400px;
    margin: 7px 0 0;
  }

  .alert.sc i {
    margin-right: 15px;
    color: rgb(255,255,255);
    font-size: 25px;
  }

  .alert.sc span {
    color: rgb(255,255,255);
    position: relative;
  }

</style>
