<template>
  <div id="page0" class="row">
    <left-info-panel></left-info-panel>
    <div class="col-6 d-flex justify-content-center align-items-center bg-secondary sc-right-block">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <div class="card sc">
                <div class="card-body sc">
                  <label class="form-label d-block sc sc-fs-14">{{ t.CommonTeamValues }}</label>
                  <h4 class="card-title sc">{{ t.TestBeginning }}</h4>
                  <hr class="sc-hr">
                  <div class="row row-cols-2 sc">
                    <div class="col d-flex justify-content-start align-items-center p-0 sc-w-80p">
                      <strong class="fs-6 fw-bolder w-100" style="font-size: 18px;">{{ teamName }}</strong>
                    </div>
                    <div class="col d-flex justify-content-end align-items-center p-0 sc-w-20p">
                      <label :class="currentLanguageCode" class="col-form-label m-0 shortcut">{{ playersCount }} {{ t.PlayersCountShortcut }}</label>
                    </div>
                  </div>

                  <form class="row" id="loginForm">
                    <div class="col-auto">
                      <label class="row sc-ml-10">{{ t.FirstName }}</label>
                      <input v-bind:style="{'border-color':(firstNameError === '')?'':'#FF4D4D'}"
                             type="text" class="row player-fn" name="firstName" id="firstName" v-model="firstName">
                    </div>
                    <div class="col-auto">
                      <label class="row">{{ t.LastName }}</label>
                      <input v-bind:style="{'border-color':(lastNameError === '')?'':'#FF4D4D'}"
                             type="text" class="row player-ln" name="lastName" id="lastName" v-model="lastName">
                    </div>
                    <p class="sc-form-p" v-html="t.TermsAndPolicy"></p>
                    <button v-bind:class="loginButtonClassObject" class="btn btn-primary sc-login-button" type="button"
                            @click.self.prevent="login">
                      {{ t.Start }}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div :class="{invisible: (loginError === '')}" class="row sc-error-block">
            <div class="col d-flex justify-content-center">
              <div class="d-flex alert alert-danger text-start sc" role="alert">
                <i class="fas fa-info-circle"></i>
                <span class="d-inline-block">
                  <strong>{{ loginError }}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <languages :in-the-game=false></languages>
      <social-links></social-links>
    </div>
  </div>
</template>

<script>
import { mapWritableState } from 'pinia'
import {useMainStore} from "@/stores/main";
import LeftInfoPanel from "@/components/leftInfoPanel.vue";
import SocialLinks from "@/components/socialLinks.vue";
import {i18nMixin} from "@/mixins/i18n";
import Languages from "@/components/languages.vue";

export default {
  name: "login",
  mixins: [i18nMixin], // TODO test csrfAjaxMixin!
  components: {Languages, SocialLinks, LeftInfoPanel},
  delimiters: ['[[', ']]'],
  data: function () {
    return {
      loginError: "",
      firstNameError: "",
      lastNameError: "",
      firstName: "",
      lastName: "",
      teamName: "",
      displayPlayersCount: false,
      playersCount: 0
    }
  },
  watch: {
    firstName: function (newName, oldName) {
      if (newName !== "") {
        this.firstNameError = this.loginError = "";
      }
    },
    lastName: function (newLastName, oldLastName) {
      if (newLastName !== "") {
        this.lastNameError = this.loginError = "";
      }
    },
  },
  computed: {
    loginButtonClassObject() {
      return {
        disabled: this.firstName === "" || this.lastName === ""
      }
    },
    ...mapWritableState(useMainStore, ["isSection0", "isSection1", "team", "game", "player", "groups",
      "description"])
  },
  created() {
    let self = this;
    this.$axios.get("/login/?g=" + this.$route.params.hash).then(function(response) {
      self.teamName = response.data["teamName"];
      self.playersCount = response.data["playersCount"];
    }).catch(function (error) {
      self.$log.error("Method: 'created'. Url: '/login/?g='. Action: login. Output: ", error);
    });
  },
  methods: {
    login() {
      if (this.firstName === "") {
        this.firstNameError = this.loginError = this.t.LoginErrorEmpty;
      } else if (this.lastName === "") {
        this.lastNameError = this.loginError = this.t.LoginErrorEmpty;
      } else {
        let self = this;
        let fd = new FormData();
        fd.set("player", [this.firstName, this.lastName].join(" "));

        this.$axios.post("/login/?g=" + this.$route.params.hash, new URLSearchParams(fd).toString()).then(function(response) {
          if ("redirect" in response.data && response.data["redirect"] !== "") {
            self.$router.push({ path: response.data["redirect"] })
          } else {
            self.isSection0 = false;
            self.isSection1 = true;
            self.team = response.data["teamName"];
            self.game = response.data["gameTitle"];
            self.player = response.data["player"];
            self.groups = response.data["values_groups"];
            self.description = JSON.parse(response.data["description"]);
            self.$router.push({ path: '/phase1' })
          }
        }).catch(function (error) {
          self.$log.error("Method: 'login'. Url: '/login/?g='. Action: login. Output: ", error);
          // TODO TEST IT!
          self.loginError = self.firstNameError = self.lastNameError = error.response.data.error;
        });
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  #page0 {
    height: 100%;
    padding: 20px;
    margin: 0;
  }

  .player-fn {
    width: 145px;
    height: 56px;
    border-radius: 4px;
    margin-left: 10px;
    margin-right: 5px;
  }

  .player-ln {
    width: 145px;
    height: 56px;
    border-radius: 4px;
  }

  .sc-right-block {
    height: 100%;
    border-radius: 10px;
  }

  .card.sc {
    width: 400px;
    height: 460px;
    margin: 0;
    position: relative;
  }

  .card-body.sc {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    padding: 0 25px;
  }

  .card-title.sc {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-left: 25px;
    margin-bottom: 27px;
  }

  .sc-hr {
    width: 100%;
    margin: 0 0 0 0;
    padding-right: 0;
    padding-left: 0;
    border-color: #99B2F1;
  }

  .sc-w-80p {
    width: 80%
  }

  .sc-w-20p {
    width: 20%
  }

  .shortcut.EN {
    font-size: 14px;
  }

  .row.sc {
    width: 100%;
    margin: 20px 0;
    padding-right: 25px;
    padding-left: 25px;
  }

  .form-label.sc {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 8px;
    margin-left: 25px;
    font-size: 16px;
  }

  .sc-form-p {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    margin-top: 15px;
    padding-left: 25px;
    margin-bottom: 25px;
    padding-right: 25px;
  }

  .sc-form-p span {
    font-weight: 700;
    margin-left: 3px;
  }

  .sc-login-button {
    width: 300px;
    height: 56px;
    margin-left: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
  }

  .sc-fs-14 {
    font-size: 14px!important;
  }

  .sc-error-block {
    --bs-danger: #dc3545;
    --bs-danger-rgb: 220,53,69;
  }

  .alert.sc {
    padding-right: 25px;
    padding-left: 25px;
    background: rgb(254,88,88);
    width: 400px;
    margin: 7px 0 0;
  }

  .alert.sc i {
    margin-right: 15px;
    color: rgb(255,255,255);
    font-size: 25px;
  }

  .alert.sc span {
    color: rgb(255,255,255);
    position: relative;
  }

  .sc-ml-10 {
    margin-left: 10px!important;
  }

</style>
