<template>
  <div id="profile-page" class="row">
    <results-modal :open="openModal" :team="teamName" :players-count="playersCount" :top="currentTop"
                   :vote-result="currentVoteResult" :start-date="currentStartDate"></results-modal>
    <results-to-download :open="downloadModal" :team="teamName" :players-count="playersCount" :top="currentTop"
                         :vote-result="currentVoteResult" :start-date="currentStartDate"></results-to-download>
    <left-info-panel></left-info-panel>
    <div class="col-6 d-flex justify-content-center align-items-center bg-secondary sc-right-block">
      <div class="row">
        <div class="col">
          <div class="row mb-3">
            <div class="col text-end">
              <p class="head-label sc">На сьогодні досить? <b><a href="#" @click.prevent.stop="signOut">Вийти</a></b></p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <div class="card sc pb-3">
                <div class="card-body sc">
                  <h4 class="card-title sc">{{ cardTitle }}</h4>
                  <label :class="{'d-block': !isPremium, 'd-none': isPremium}"
                         class="form-label d-block sc">{{ playersCount }} ос.</label>
                  <label class="form-label d-block mt-3 sc"><strong>{{ cardSubTitle }}</strong></label>
                  <div class="records-list">
                    <div class="row record sc" v-for="test in tests">
                      <div class="col p-0">{{ (isPremium)?test.team_name:test.start_date }}</div>
                      <div class="col text-end justify-content-end">
                        <div class="d-inline-flex sc-game-info">{{ parseFloat(test.vote_results).toFixed(0) }}%</div>
                        <div class="d-inline-flex sc-game-info">
                          <a href="#" @click.prevent.stop="showResults(test.top, test.vote_results, test.start_date,
                                    test.team_name, test.players_count)"
                             @mouseover.self="onHover" @mouseout.self="outHover">
                            <i class="fas fa-eye text-secondary"/>
                          </a>
                        </div>
                        <div class="d-inline-flex sc-game-info">
                          <a href="#" @click.prevent.stop="download(test.top, test.vote_results, test.start_date,
                                    test.team_name, test.players_count)"
                             @mouseover.self="onHover" @mouseout.self="outHover">
                            <i class="fas fa-arrow-down text-secondary"/>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button class="btn btn-primary sc new-test mt-3" type="button" @click.self.prevent="generateNewTest">
                    новий тест
                  </button>
                  <a class="btn d-flex justify-content-center align-items-center border border-3 border-secondary donate"
                     type="button"
                     role="button" target="_blank" href="https://buy.stripe.com/test_6oEfZncD8aGDd4Q7ss">
                    донат
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div :class="{invisible: (registerError === '')}" class="row sc-error-block">
            <div class="col d-flex justify-content-center">
              <div class="d-flex alert alert-danger text-start sc" role="alert">
                <i class="fas fa-info-circle"></i>
                <span class="d-inline-block">
                  <strong>{{ registerError }}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <social-links></social-links>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import LeftInfoPanel from "@/components/leftInfoPanel";
import ResultsModal from "@/components/resultsModal";
import ResultsToDownload from "@/components/resultsToDownload";
import {csrfAjaxMixin} from "@/mixins/csrf";
import SocialLinks from "@/components/socialLinks.vue";

export default {
  name: "profile-page",
  components: {SocialLinks, ResultsToDownload, ResultsModal, LeftInfoPanel},
  delimiters: ['[[', ']]'],
  mixins: [csrfAjaxMixin],
  data: function () {
    return {
      registerError: "",
      openModal: false,
      downloadModal: false,
      currentTop: [],
      currentVoteResult: 0,
      currentStartDate: "",
      teamName: "",
      playersCount: 0,
      isPremium: false,
      tests: [],
      cardSubTitle: "Останні результати",
      cardTitle: ""
    }
  },
  created: function (){
    let self = this;
    this.$axios.get('/ispremium/').then(function(response) {
      self.isPremium = true;
    }).catch(function (error) {
      self.isPremium = false;
    });
    this.fetchTests();
  },
  methods: {
    onHover(event) {
      $(event.target).children().removeClass("text-secondary").addClass("text-primary");
    },
    outHover(event) {
      $(event.target).children().addClass("text-secondary").removeClass("text-primary");
    },
    generateNewTest(){
      let self = this;
      if (this.isPremium) {
        self.$router.push('/create');
      } else {
        let data = {
          "company_name": this.teamName,
          "players_count": this.playersCount
        };
        this.$axios.post('/register/', data).then(function (response) {
          self.$log.info("Method: generateNewTest. Url: '/register/'. Action: profile. Output: ", response);
          self.$router.push('/link/')
        }).catch(function (error) {
          let errMsg = "";
          if (error.response) {
            errMsg = error.response;
            self.registerError = error.response.data.error;
          } else if (error.request) {
            errMsg = error.request;
          } else {
            errMsg = error.message;
          }
          self.$log.error("Method: generateNewTest. Url; '/register/'. Action: profile. Output: ", errMsg);
        });
      }
    },
    signOut() {
      let self = this;
      this.$axios.post('/accounts/logout/', {})
           .then(function (response) {
              self.$log.info("Method: 'signOut'. Url: '/accounts/logout/'. Action: profile. Output: ", response);
              self.$router.push('/signin')
           }).catch(function (error) {
              let errMsg = "";
              if (error.response) {
                errMsg = error.response;
                self.registerError = error.response.data.error;
              } else if (error.request) {
                errMsg = error.request;
              } else {
                errMsg = error.message;
              }
              self.$log.error("Method: 'signOut'. Url: '/accounts/logout/'. Action: profile. Output: ", errMsg);
      });
    },
    changeTeamName(){
      //TODO
    },
    showResults(resultedValues, voteResult, startDate, teamName, playersCount) {
      this.openModal = !this.openModal;
      this.currentTop = resultedValues;
      this.currentVoteResult = parseFloat(voteResult).toFixed(0);
      this.currentStartDate = startDate;
      if (teamName !== undefined && playersCount !== undefined) {
        this.teamName = teamName;
        this.playersCount = playersCount;
      }
    },
    download: function(resultedValues, voteResult, startDate, teamName, playersCount) {
      this.downloadModal = !this.downloadModal;
      this.currentTop = resultedValues;
      this.currentVoteResult = parseFloat(voteResult).toFixed(0);
      this.currentStartDate = startDate;
      if (teamName !== undefined && playersCount !== undefined) {
        this.teamName = teamName;
        this.playersCount = playersCount;
      }
    },
    fetchTests() {
      let self = this;
      this.$axios.get("/profile/").then(function(response) {
        if (self.isPremium) {
          self.cardTitle = "Останні результати";
          self.cardSubTitle = "Команди";
          response.data.forEach(function(team, index){
            team["tests"].forEach(function (test, index){
              test["team_name"] = team["company_name"];
              test["players_count"] = team["players_count"];
              self.tests.push(test);
            });
          });
        } else {
          self.cardTitle = response.data[0]["company_name"];
          self.playersCount = response.data[0]["players_count"];
          self.teamName = response.data[0]["company_name"];
          self.tests = response.data[0]["tests"]
        }
      }).catch(function (error) {
        self.$log.error("Method: 'fetchTests'. Url: '/profile/'. Action: profile. Output: ", error);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  body {
    overflow: hidden!important;
  }

  #profile-page {
    height: 100%;
    padding: 20px;
    margin: 0;
  }

  .head-label.sc {
    padding-right: 5px;
    margin: 0;
    color: white;
  }

  .head-label.sc a {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }

  .sc-signup-google span {
    position: relative;
    top: -6px;
  }

  .sc-right-block {
    height: 100%;
    border-radius: 10px;
  }

  .card.sc {
    width: 350px;
    min-height: 300px;
    margin: 0;
    position: relative;
  }

  .card-body.sc {
    margin: 0;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    padding: 0 25px;
  }

  .card-title.sc {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-top: 24px;
    margin-left: 25px;
  }

  .card-title.sc i {
    color: #C4C4C4;
    margin-left: 10px;
    font-size: 18px;
  }

  .form-label.sc {
    width: 100%;
    margin-bottom: 8px;
    margin-left: 25px;
    font-size: 16px;
  }

  .sc-form-p span {
    font-weight: bold;
    margin-left: 3px;
  }

  .records-list {
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .record.sc {
    margin-left: 25px;
    margin-bottom: 10px;
  }

  .record i {
    color: #C4C4C4;
    font-size: 14px;
  }

  .sc-game-info {
    margin-left: 10px;
  }

  .btn.btn-primary.sc {
    width: 300px;
    height: 56px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .donate {
    width: 300px;
    height: 56px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
  }

  .border-secondary:hover {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-primary)!important;
  }

  .sc-error-block {
    --bs-danger: #dc3545;
    --bs-danger-rgb: 220,53,69;
  }

  .alert.sc {
    padding-right: 25px;
    padding-left: 25px;
    background: rgb(254,88,88);
    width: 350px;
    margin: 7px 0 0;
  }

  .alert.sc i {
    margin-right: 15px;
    color: rgb(255,255,255);
    font-size: 25px;
  }

  .alert.sc span {
    color: rgb(255,255,255);
    position: relative;
  }

</style>
