<template>

      <span>{{ getCompanyTitle }}</span>
<!--      <i class="info circle icon inline-icon"-->
<!--         v-bind:class="{ grey: !displayCompanyInfo, lightgrey: displayCompanyInfo}"-->
<!--         @mouseover.self="displayCompanyInfo = true"-->
<!--         @mouseleave.self="displayCompanyInfo = false">-->
<!--      </i>-->
<!--      <div class="ui label company-info" v-bind:class="{ invisible: !displayCompanyInfo }">-->
<!--        <table class="ui very basic collapsing celled table">-->
<!--          <thead></thead>-->
<!--          <tbody>-->
<!--          <tr v-for="(value, key) in tgpd().description">-->
<!--            <td><div class="content">{{ key }}</div></td>-->
<!--            <td class="item"><p v-for="item in value">{{ item }}</p></td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
<!--      </div>-->
</template>

<script>
import {mapState} from 'pinia';
import {useMainStore} from "@/stores/main";

export default {
  name: "company-info",
  data: function () {
    return {
      displayCompanyInfo: false
    }
  },
  computed: {
    ...mapState(useMainStore, ["tgpd"]),
    getCompanyTitle() {
      return this.tgpd.description.hasOwnProperty("Стартап") ? this.tgpd.description["Стартап"][0] : this.tgpd.team;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
