<template>
  <button v-bind:class="{ disabled: !readyNext, ready: readyNext }" @click.preventDefault="goto"
      class="btn btn-secondary d-flex justify-content-center align-items-center nextStep" type="button">
    <slot name="content">
      <span>{{ t.Next }}</span>
      <i class="fas fa-arrow-right sc"></i>
    </slot>
  </button>
</template>

<script>
import {i18nMixin} from "@/mixins/i18n";

export default {
  name: 'next-button',
  mixins: [i18nMixin],
  data: function () {
    return {
    }
  },
  props: ["readyNext"],
  methods: {
    goto() {
      this.$emit("goto");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.nextStep {
  width: 100px;
  height: 40px;
  background: var(--bs-white);
  color: var(--bs-light);
  border-style: none;
  border-radius: 4px;
}

.nextStep.ready {
  color: white;
  background-color: var(--bs-secondary);
}

.nextStep.ready:hover {
  color: white;
  background-color: var(--bs-primary);
}

.fa-arrow-right.sc {
  height: 14px;
  margin-left: 15px;
}
</style>
