<template>
  <div class="row d-flex align-items-center flex-grow-1">
    <div class="progress col-10 d-flex p-0"  role="progressbar" aria-label="6px high"
         :aria-valuenow="percent" aria-valuemin="0" aria-valuemax="100" style="height: 6px">
      <div class="progress-bar" :style="{ 'width': percent + '%' }"></div>
    </div>
    <div class="col-2 d-flex align-items-center"><span>{{ label }}</span></div>
  </div>
</template>

<script>
    export default {
        name: "progress-bar",
        props: ["total"],
        data() {
            return {
                percent: 0,
                current: 0,
            };
        },
        computed: {
            label() {
                return `${this.current}/${this.total}`;
            },
            delta() {
                return 100/this.total;
            }
        },
        methods: {
            decrease() {
                this.percent -= this.delta;
                this.current--;
                if (this.percent <= 0) {
                    this.percent = 0;
                    this.current = 0;
                }
            },
            increase() {
                this.percent += this.delta;
                this.current++;
                if (this.current >= this.total) {
                    this.percent = 100;
                    this.current = this.total;
                    this.$emit("completed");
                }
            },
            getCurrent() {
                return this.current;
            }
        },
    }
</script>

<style scoped>

 .progress {
   background-color: var(--bs-white);
 }

 .progress-bar {
   background-color: var(--bs-secondary);
 }
</style>
