import { t, availableLanguages, setLanguage, currentLanguageCode } from '@/locale/language'
import $ from "jquery";

export const i18nMixin = {
    data: function () {
        return {
            t: t,
            availableLanguages: availableLanguages,
            currentLanguageCode: currentLanguageCode,
            languageMap: {
                "UA": "uk",
                "EN": "en",
                "RU": "ru",
            }
        }
    },
    created: function () {
        this.$log.info("i18nMixin created with language ", this.currentLanguageCode)
        this.$cookies.set('django_language', this.languageMap[this.currentLanguageCode]);
        $.ajaxSetup({
            headers: {
                'Accept-Language': this.languageMap[this.currentLanguageCode]
            }
        });
        this.$axios.defaults.headers.post['Accept-Language'] = this.languageMap[this.currentLanguageCode]

    },
    methods: {
        changeLanguage(language) {
            setLanguage(language);
            this.$cookies.set('django_language', this.languageMap[language]);
            $.ajaxSetup({
                headers: {
                    'Accept-Language': this.languageMap[language]
                }
            });
            this.$axios.defaults.headers.post['Accept-Language'] = this.languageMap[language]
            this.$log.info("i18nMixin language changed to ", language)
        }
    },
}