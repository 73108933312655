<template>
  <a class="d-flex li-row position-absolute rounded-3 justify-content-center align-items-center"
     target="_blank"
     href="https://www.linkedin.com/company/senscreed/">
    <i class="li-icon"></i>
  </a>
</template>

<script>
export default {
  name: "social-links"
}
</script>

<style scoped>

  .li-row {
    background-color: white;
    display: block;
    width: 40px;
    height: 40px;
    bottom: 4%;
    right: 2.5%;
  }

  .li-icon {
    height: 24px;
    width: 24px;
    background:url('~@/assets/linkedin.png') no-repeat;
    background-size: 24px 24px;
    display: inline-block;
  }

</style>